import { Checkbox, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { CgCloseO } from "react-icons/cg";
import { MdClose } from "react-icons/md";
import NormalTable from "../../../../Components/AppTable/AppTable";
import "./conflicts.css";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { useDispatch, useSelector } from "react-redux";
import { viewPdfDocuementHandler } from "../../../../Redux/reducers/Order";
const { Panel } = Collapse;

export default function Conflicts({
  orderContorller,
  conflicts = [],
  viewPdfDocument,
  isconflicts = false,
  onclose = false,
  onchek = false,
}) {
  const [checked, setCheked] = useState([])
  const tools = [
    "pager",
    "spacer",
    "zoomInOut",
    "zoom",
    "download",
    "print",
    "selection"
  ];
  // useEffect(() => {
  //   // onchek && onchek(checked)
  // }, [checked])
  const handleSubmit = () => {
    onchek && onchek(checked)
    setCheked([])
  }

  return (
    <Collapse
      className="rounded-3 border-0 text-white mt-2  position-relative"
      expandIcon={() => <></>}
      accordion
      defaultActiveKey={"1"}
    >
      {isconflicts &&  <div style={{ zIndex: 10, top: 5, right: 10 }} className="rounded-circle position-absolute">
        <CgCloseO
          onClick={() => {
            onclose && onclose(false);
          }}
          size={25}
          color="yellow"
          cursor={"pointer"}
        />
      </div>}
      {isconflicts && (
        <Panel
          className="main-bg-color rounded-3 "
          header={
            <div
              className=" text-white d-flex justify-content-between "
              style={{ fontSize: 18, marginBottom: 0 }}
            >
              Conflicts
            </div>
          }
          style={{ fontSize: 18 }}
          key="1"
        >
          <div
            style={{ maxHeight: "130vh", overflowY: "auto" }}
            className="p-2 gray-table-col conflicts"
          >
            {checked.length > 0 && <button onClick={handleSubmit} className="btn btn-sm btn-success my-2">Update Conflicts</button>}

            <NormalTable
              pagination={false}
              activeState={false}
              dataSource={conflicts}
              columns={[
                {
                  title: "Review",
                  dataIndex: "is_checkbox",
                  key: "is_checkbox",
                  align: "center",
                  width: 35,
                  render: (value, record) => {
                    return (
                      <div>
                        {Boolean(!record.is_fixed) && Boolean(value) && (
                          <Checkbox
                            checked={checked.includes(record?.id)}
                            onChange={() => {
                              if (checked.includes(record?.id)) {
                                setCheked(checked.filter(ele => ele !== record?.id))
                              } else {
                                setCheked([...checked, record?.id])
                              }

                            }}
                          ></Checkbox>
                        )}
                        {Boolean(record.is_fixed) && Boolean(value) && (
                          <Checkbox checked disabled></Checkbox>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "Parcel No",
                  dataIndex: "cad_property_id",
                  key: "cad_property_id",
                  align: "center",
                  width: 200,
                  render: (value) => {
                    return <div className="text-dark">{value || "-"}</div>;
                  },
                },
                {
                  title: "Errors",
                  dataIndex: "message",
                  key: "message",
                  align: "center",
                  width: 200,
                  render: (value, record) => {
                    return (
                      <div className="text-danger hover-text-comp position-relative">
                        {value}
                        {record?.jurisdiction_code_message && (
                          <div className="hover-text-content border p-2 rounded-3 shadow-sm">
                            <p className="d-flex justify-content-center align-items-center ">
                              {record.jurisdiction_code_message}
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  dataIndex: "action_required",
                  key: "action_required",
                  align: "center",
                  width: 200,
                  render: (value) => {
                    return <div className="text-success">{value}</div>;
                  },
                },
                {
                  title: "Validated",
                  dataIndex: "is_fixed",
                  key: "is_fixed",
                  align: "center",
                  width: 35,
                  render: (value) => {
                    return value ? (
                      <AiOutlineCheck color="green" />
                    ) : (
                      <MdClose color="red" />
                    );
                  },
                  filters: [
                    {
                      text: "Yes",
                      value: true,
                    },
                    {
                      text: "No",
                      value: false,
                    },
                    {
                      text: "Review",
                      value: 1,
                    },
                  ],
                  defaultFilteredValue: [false, 1],
                  onFilter: (value, record) => {
                    if (value === 1) {
                      return record.is_checkbox === value;
                    } else {
                      return Boolean(record.is_fixed) === Boolean(value);
                    }
                  },
                  filterMode: "tree",
                },
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  align: "center",
                  width: 120,
                  render: (value) => {
                    return <div className="text-primary">{value || "-"}</div>;
                  },
                },
              ]}
            />
          </div>
        </Panel>
      )}
      {viewPdfDocument && (
        <div className="view-docs">
          <div style={{ zIndex: 10 }} className="close bg-white rounded-circle">
            <CgCloseO
              onClick={() => {
                onclose && onclose(true, "pdf");
              }}
              size={25}
              color="red"
              cursor={"pointer"}
            />
          </div>
          {viewPdfDocument ? (
            <PDFViewer
              data={atob(viewPdfDocument)}
              style={{
                minHeight: "130vh",
                maxHeight: "130vh",
              }}
              tools={tools}
            />
            // <embed
            //   src={orderContorller.pdfDoc.base64}
            //   width="100%"
            //   style={{
            //     minHeight: "130vh",
            //     maxHeight: "130vh",
            //   }}
            // />
          ) : (
            <div className="img-view w-100 my-2">
              <img width={"100%"} src={orderContorller.pdfDoc.base64} alt="" />
            </div>
          )}
        </div>
      )}
    </Collapse>
  );
}

