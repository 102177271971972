import React, { useState } from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import Labels from "./Labels/Labels";
import Status from "./Status/Status";
import Notification from "./Notification/Notification";
import { Jurisdiction } from "./Jurisdiction/Jurisdiction";
import { TaxCollector } from "./TaxCollector/TaxCollector";
import AnnotationMain from "./AnnotationsBkp/Annotaion";
import { useEffect } from "react";
import HoaMgmt from "./HOA MGMT/HoaMgmt";
import Hoa from "./HOA/Hoa";
import CadTab from "./Cads/cadTab";
import { TaxCad } from "./TaxCad/TaxCad";
import OrderStatus from "./Orders Status/OrdersStatus";
import HoaStatus from "./HoaStatus/HoaStatus";
import ExemptionStatus from "./ExemptionMaster/Exemption";
import ActiveCounty from "./ActiveCounties/ActiveCounty";
import Subdivision from "./Subdivision/Subdivision";

const { TabPane } = Tabs;

export const ToolsTab = () => {
	const [key, setKey] = useState("labels");
	const [cadTabKey, setCadTabkey] = useState(false);

	//redux store using

	const { toolsTabs } = useSelector((state) => state.flagRoles);
	//create function for settings tabs
	function matchTab(value) {
		if (toolsTabs.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}
	useEffect(() => {
		setKey(toolsTabs[0]);
	}, [toolsTabs]);

	return (
		<>
			<Tabs
				activeKey={key}
				defaultActiveKey="1"
				className="title-container app-tab"
				onChange={(k) => {
					setKey(k);
					if (k === "cads") {
						setCadTabkey(!cadTabKey);
					}
				}}
				tabBarGutter={25}
			>
				{
					//matching base on roles toolsTab Flag
					matchTab("labels") && (
						<TabPane tab="Labels" key="labels">
							<Labels refresh={key} />{" "}
						</TabPane>
					)
				}

				{
					//matching base on roles toolsTab Flag
					matchTab("hoa-mgmt") && (
						<TabPane tab="HOA MGMT" key="hoa-mgmt">
							<HoaMgmt refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("hoa") && (
						<TabPane tab="HOA" key="hoa">
							<Hoa refresh={key} />
						</TabPane>
					)
				}
				{
          
					matchTab("subdivision") && (
					<TabPane tab="Sub Division" key="subdivision">
						<Subdivision refresh={key}/>
					</TabPane>
					)
				
				
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("taxcollector") && (
						<TabPane tab="Tax Collector" key="taxcollector">
							<TaxCollector refresh={key} />{" "}
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("jurisdiction") && (
						<TabPane tab="Jurisdiction" key="jurisdiction">
							<Jurisdiction refresh={key} />{" "}
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("activeCounty") && (
						<TabPane tab="Active County" key="activeCounty">
							<ActiveCounty refresh={key} />{" "}
						</TabPane>
					)
				}

				{/* {
          //matching base on roles toolsTab Flag
          matchTab("hoa") && (
            <TabPane tab="Exemptions" key="exemptions">
              <Exemptions refresh={key} />
            </TabPane>
          )
        } */}
				{/* {
					//matching base on roles toolsTab Flag
					matchTab("closer") && (
						<TabPane tab="Closer" key="closer">
							<Closer refresh={key} />{" "}
						</TabPane>
					)
				} */}

				{
					//matching base on roles toolsTab Flag
					matchTab("status") && (
						<TabPane tab="Parcel Status" key="status">
							<Status refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("orderstatus") && (
						<TabPane tab="Order Status" key="orderstatus">
							<OrderStatus refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("hoastatus") && (
						<TabPane tab="HOA Status" key="hoastatus">
							<HoaStatus refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("notification") && (
						<TabPane tab="Notification Alert" key="notification">
							<Notification refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("exemption") && (
						<TabPane tab="Exemption" key="exemption">
							<ExemptionStatus refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("annotations") && (
						<TabPane tab="Comments" key="annotations">
							<AnnotationMain refresh={key} />
						</TabPane>
					)
				}
				{
					//matching base on roles toolsTab Flag
					matchTab("cads") && (
						<TabPane tab="Files" key="cads">
							<CadTab refresh={key} />
						</TabPane>
					)
				}

				{
					//matching base on roles toolsTab Flag
					matchTab("taxcad") && (
						<TabPane tab="Tax" key="taxcad">
							<TaxCad refresh={key} />
						</TabPane>
					)
				}

			</Tabs>
		</>
	);
};

export default ToolsTab;
