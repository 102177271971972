import React, { memo, useMemo, useState } from "react";
import { Button, Popconfirm } from "antd";
import { FaPlus } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import {
  priceFormater,
  removeBlank,
} from "../../Controllers/Functions";
import { useCallback } from "react";
import InlineTable from "../antd/Tables/InlineTable";
import { useHoaAssessmentTableMutation } from "../../Redux/Services/Hoa";
import { useSelector } from "react-redux";
const randomPass = require("secure-random-password");
const date = new Date();
export default function AssessmentFees({
  afterClose = false,
  initailValArr = [],
  updateDescription = false,
  deleteHandler = false,
  showLabel = true,
  isedit = false,

}) {
  const initailVal = {
    name: "",      // Text field
    base_amount: "",      // Number field
    assessement_type: "",          // Number field
    amount_due: "",    // Number field
    frequency: "",       // Text field
    due_one: "",     // Date field
    due_two: "" ,     // Date field
    
  };

  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);
  // console.log(initailVal,36)
  const { token } = useSelector((state) => state.auth);

  const assesmentApi = useHoaAssessmentTableMutation();
  const [assesmentCheck] = assesmentApi;

  useEffect(() => {
    setData(initailValArr);
    setValues(initailVal);
  }, [afterClose]);

  useEffect(() => {
    setData(initailValArr);
  }, [initailValArr]);

  const juriListOptionsFilter = [];
  data?.map(({ name }) => {
    juriListOptionsFilter.push(name);
  });

  const handleAdd = () => {
    const randomId = randomPass.randomPassword({
      length: 9,
      characters: randomPass.digits,
    });

    const newData = {
      tempId: randomId,
      // amount_due: 0,
      // assessement_type:"",
      // base_amount:0,
      // due_one:"",
      // due_two:"",
      // frequency:""
      // hoa_fees_type: "",
    };
    setData([...data, newData]);
    updateDescription && updateDescription(newData);
  };

  // const handleSubmit = () => {
  //   assesmentCheck({ name: values.name, token: token });
  // };
  
  

  const cols = [
    {
      title: "Assessment",
      dataIndex: "name",
      key: "name",
      type: "select",
      options:[
        {id:"N/A", name:"N/A"},
        {id:"MAINTANANCE", name:"MAINTANANCE"},
        {id:"DUES", name:"DUES"}
      ],
      align: "center",
      render: (value) => value || <>&nbsp;</>,
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Base Amount",
      dataIndex: "base_amount",
      key: "base_amount",
      align: "center",
      type: "number",
      render: (value) => (value ? priceFormater(value) : <>&nbsp;</>),
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Type",
      dataIndex: "assessement_type",
      key: "assessement_type",
      width: 100,
      align: "center",
      type: "select",
      options:[
        {id:"N/A", name:"N/A"},
        {id:"MANDATORY", name:"MANDATORY"},
        {id:"VOLUNTARY", name:"VOLUNTARY"}
      ],
      render: (value) => value || <>&nbsp;</>,
      editable: () => {
        return !isedit;
      },
    },
    // {
    //   title: "Amount Due",
    //   dataIndex: "amount_due",
    //   key: "amount_due",
    //   align: "center",
    //   type: "number",
    //   render: (value) => (value ? priceFormater(value) : <>&nbsp;</>),
    //   width: 100,
    //   editable: () => {
    //     return !isedit;
    //   },
    // },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      align: "center",
      type: "select",
      options:[
        {
          id:"N/A",
          name:"N/A"
        },
        {
          id:"ANNUAL",
          name:"ANNUAL"
        },
        {
          id:"ANNUAL IN ARREARS",
          name:"ANNUAL IN ARREARS"
        },
        {
          id:"SEMI-ANNUAL",
          name:"SEMI-ANNUAL"
        },
        {
          id:"QUARTERLY",
          name:"QUARTERLY"
        },
        {
          id:"MONTHLY",
          name:"MONTHLY"
        },
        {
          id:"ONE-TIME",
          name:"ONE-TIME"
        },
      ],
      width: 100,
      render: (value) => value || <>&nbsp;</>,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: `Due ${date?.getMonth()+"/"+date?.getFullYear()}`,
      dataIndex: "due_one",
      key: "due_one",
      align: "center",
      type: "date",
      render: (value) => value || <>&nbsp;</>,
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: `Due ${(date?.getMonth()+1)+"/"+date?.getFullYear()}`,
      dataIndex: "due_two",
      key: "due_two",
      align: "center",
      type: "date",
      width: 100,
      render: (value) => value || <>&nbsp;</>,
      editable: () => {
        return !isedit;
      },
    },

    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      align: "center",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    deleteHandler && deleteHandler(record);
    const newData = data?.filter(
      (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
    );
    setData(newData);
  };

  const oneEditFun = useCallback(
    async (value, id, type) => {
      removeBlank(value);
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        console.log(value);
        const { ...rest } = value;
        if (type === "select") {
          updateDescription && updateDescription(rest);
        } else if (type !== "select") {
          updateDescription &&
            updateDescription({
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data, updateDescription]
  );

  const data1 = useMemo(() => data, [data]);

  // const cols1 = useMemo(
  //   () =>
  //     cols.filter((ele) => {
  //       return columnshow.includes(ele.title);
  //     }),
  //   [cols]
  // );

  return (
    <div className="gray-table-col  mb-2 p-2 ">
      <div
        className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
      >
        <div className="date align-items-center d-flex gap-1">
          {showLabel ? <h6 className="text-primary mb-0 border d-inline-block p-2 px-3 rounded bg-white shadow-sm">
            Assessment Fees
          </h6> : <div></div>}
        </div>
        {!isedit && (
          <div className={`add_icon ms-auto`} onClick={handleAdd}>
            <FaPlus />
          </div>
        )}
      
      </div>

      <div className="bg-white shadow-sm rounded border">
        <InlineTable
          onEdit={oneEditFun}
          setData={setData}
          data={data1}
          cols={cols}
        />
      </div>
    </div>
  );
}
