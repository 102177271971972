import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";

import { FaPlus, FaStarOfLife } from "react-icons/fa";
import "./MultiHoaAssessment.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineAssessment } from "react-icons/md";
// import { MdOutlineAssessment  } from "react-icons/ri";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
// import AppNumeric from "../AppNumeric/AppNumeric";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export default function MultiHoaAssessment({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  _id,
}) {
  const [active, setActive] = useState("2");

  const initailVal = {
    name: "",
    base_amount:"",
    assessement_type:"",
    frequency:"",
    hoa_info_subdivision_id:""
    
  };
  const [values, setValues] = useState(initailVal);
  // const [,setShowDate] = useState(true);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);

  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { name, base_amount, frequency, assessement_type, hoa_info_subdivision_id} = values;

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
      title: <span>Name</span>,
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: <span>Base Amount</span>,
      dataIndex: "base_amount",
      key: "base_amount",
      align: "center",
    },
    {
        title: <span>Assessment Type</span>,
        dataIndex: "assessement_type",
        key: "assessement_type",
        align: "center",
    },
    {
        title: <span>Frequency</span>,
        dataIndex: "frequency",
        key: "frequency",
        align: "center",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const inputs = [
    {
      id: 1,
      name: "name",
      _id: "name",
      type: "text",
      value: name || "",
      column: "3",
      placeholder: "Enter name",
      errorMessage: "Please enter name!",
    //   required: true,
    //   icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 4545,
      name: "base_amount",
      _id: "base_amount",
      type: "number",
      value: base_amount || "",
      column: "3",
      maxLength: 9,
      placeholder: "Enter BaseAmount",
      errorMessage: "Please enter BaseAmount!",
    },
    {
        id: 4546,
        name: "assessement_type",
        _id: "assessement_type",
        type: "text",
        value: assessement_type || "",
        column: "3",
        // maxLength: 9,
        placeholder: "Enter Assessment Type",
        errorMessage: "Please enter AssessmentType!",
    },
      {
        id: 4547,
        name: "frequency",
        _id: "frequency",
        type: "text",
        value: frequency || "",
        column: "3",
        // maxLength: 9,
        placeholder: "Enter Frequency",
        errorMessage: "Please enter frequency!",
      },
    // {
    //   id: 454455,
    //   name: "verify_date",
    //   _id: "verify_date",
    //   type: "date",
    //   usaFormat: true,
    //   value: verify_date || "",
    //   column: "3",
    //   placeholder: "Enter Verify Date",
    //   errorMessage: "Please enter verify date!",
    // },
  ];

  const onChange = (e, date, dateFormat) => {
    setGetErr(false);
    console.log("test", date)
    if (dateFormat) {
      setValues({
        ...values,
        [e]: date,
      });
    }
    else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    //   if (name === "fees") {
    //     setValues({
    //       ...values,
    //       [name]: value.replace(/[^0-9.]/gi, ""),
    //     });
    //   }
    }
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });

      setData([...newData, { ...values}]);
      updateContact && updateContact({ ...values });
      setAddRowToggler(false);
      setEditId(null);
      // setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          // fees: values?.fees,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, temp_id: randomId });
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setValues({ ...initailVal})
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <MdOutlineAssessment  size={15} /> <span>Assessment</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>
            <div
              className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
            >
              {/* <div className="date align-items-center d-flex gap-1">
                <h6 className="align-items-center d-flex gap-2 fs-13">
                  <span className="text-dark heading-5"> Verified Date:</span>{" "}
                </h6>
              </div> */}
              {/* {inputs.map((input) => (
                <>
                  {input.type === "date" && (
                    <AppInput
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={(name, date, dateFormat) => {
                        // setShowDate(true);

                        if (dateFormat) {
                          getDate && getDate(date);
                          setValues({ ...values, verify_date: date });
                        }


                      }}
                    />
                  )}
                </>
              ))} */}
              {!addRowToggler && (
                <div className={`add_icon ms-auto`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>

            <AppTable
              pagination={false}
              dataSource={data}
              columns={columns}
            />

            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}
            {/* //loading  */}
            {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

            {addRowToggler && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "text" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                    {input.type === "number" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                    {/* {input.name === "fees" && (
                      <AppNumeric
                        {...input}
                        type="text"
                        defaultSet={afterClose}
                        value={values[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        prefix={"$"}
                        decimalScale={7}
                        onChange={(value) => {
                          setValues({ ...values, fees: value });
                        }}
                      />
                    )} */}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${!name || !base_amount || !assessement_type || !frequency ? "disabled" : "btn-success"
                        } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
