import React, { memo, useMemo, useState } from "react";
import { Button, Popconfirm } from "antd";
import { FaPlus } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import {
  priceFormater,
  removeBlank,
} from "../../Controllers/Functions";
import { useCallback } from "react";
import InlineTable from "../antd/Tables/InlineTable";
const randomPass = require("secure-random-password");

export default memo(function MultiDescription({
  afterClose = false,
  initailValArr = [],
  updateDescription = false,
  deleteHandler = false,
  showLabel = false,
  isedit = false,

}) {
  const initailVal = {
    fees: "",
    amount: "",
    is_required: "YES",
    notes: "",
  };
  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);

  useEffect(() => {
    setData(initailValArr);
    setValues(initailVal);
  }, [afterClose]);

  useEffect(() => {
    if(!data.length){
    setData(initailValArr);
    }
  }, [initailValArr]);

  // const juriListOptionsFilter = [];
  // data?.map(({ hoa_fees_type }) => {
  //   juriListOptionsFilter.push(hoa_fees_type);
  // });

  const handleAdd = () => {
    const randomId = randomPass.randomPassword({
      length: 9,
      characters: randomPass.digits,
    });

    const newData = {
      tempId: randomId,
      hoa_fees_type: "",
    };
    setData([...data, newData]);
    updateDescription && updateDescription(newData);
  };

  const cols = [
    {
      title: "Fees",
      dataIndex: "fees",
      key: "fees",
      align: "center",
      type: "textarea",
      render: (value) => value || <>&nbsp;</>,
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Required",
      dataIndex: "is_required",
      key: "is_required",
      align: "center",
      type: "select",
      options:[
        {id:"YES", name:"YES"},
        {id:"NO", name:"NO"},
      ],
      render: (value) => value || <>&nbsp;</>,
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "center",
      type: "number",
      render: (value) => (value ? priceFormater(value) : <>&nbsp;</>),
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "center",
      type: "textaria",
      render: (value) => (value ? value : <>&nbsp;</>),
      width: 100,
      editable: () => {
        return !isedit;
      },
    },

    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      align: "center",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    deleteHandler && deleteHandler(record);
    const newData = data?.filter(
      (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
    );
    setData(newData);
  };

  const oneEditFun = useCallback(
    async (value, id, type) => {
      removeBlank(value);
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { hoa_fees_type_id, ...rest } = value;
        if (type === "select") {
          updateDescription && updateDescription(rest);
        } else if (type !== "select") {
          updateDescription &&
            updateDescription({
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data,updateDescription]
  );

  // const data1 = useMemo(() => data, [data]);

  // const cols1 = useMemo(
  //   () =>
  //     cols.filter((ele) => {
  //       return columnshow.includes(ele.title);
  //     }),
  //   [cols]
  // );

  return (
    <div className="gray-table-col  mb-2 p-2 ">
      <div
        className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
      >
        <div className="date align-items-center d-flex gap-1">
          {showLabel ? <h6 className="text-primary mb-0 border d-inline-block p-2 px-3 rounded bg-white shadow-sm">
          Fees
          </h6> : <div></div>}
        </div>
        {!isedit && (
          <div className={`add_icon ms-auto`} onClick={handleAdd}>
            <FaPlus />
          </div>
        )}
      </div>

      <div className="bg-white shadow-sm rounded border">
        <InlineTable
          onEdit={oneEditFun}
          setData={setData}
          data={data}
          cols={cols}
        />
      </div>
    </div>
  );
});
