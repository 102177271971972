import { Alert, notification, Radio, Tabs,Popconfirm,Button } from "antd";
import { Row } from "react-bootstrap";
import React, { useEffect, useState ,useMemo} from "react";
import { useSelector } from "react-redux";
import AppInput from "../../../Components/AppInput/AppInput";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import AppButton from "../../../Components/AppButton/AppButton";
import Input from "react-phone-number-input/input";
import MultiNotes from "../../../Components/MultiDescription/MultiNotes";
import { useEditAnnotationPostMutation } from "../../../Redux/Services/Annotation";
import {
  useActiveMasterAssociationMutation,
  useActiveSubAssociationMutation,
  useAddHoaMutation,
  useEditHoaQuery,
  useHoaSelectListMutation,
  useHoaTypeListQuery,
  useUpdateHoaMutation,
} from "../../../Redux/Services/Hoa";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import {
  asendingList,
  dateFormat,
  removeBlank,
  returnArryObjID,
  scrollComp,
} from "../../../Controllers/Functions";
import AppCheckbox from "../../../Components/AppCheckbox/AppCheckbox";
import MultiManagement from "../../../Components/MultiManagement/MultiManagement";
import MultiSubdivisions from "../../../Components/MultiSubdivisions/MultiSubdivisions";
import {
  useHoaDisplayOnCertListQuery,
  useHoaMgmtlistQuery,
  useHoaReadOnlyListQuery,
} from "../../../Redux/Services/HaoMgmt";
import MultiEmails from "../../../Components/MultiEmail/MultiEmails";
import { useCycleListQuery } from "../../../Redux/Services/Cycles";
import AppTextEditor from "../../../Components/AppTextEditor/AppTextEditor";
import MultiCheckList from "../../../Components/MultiCheckList/MultiCheckList";
import { useCallback } from "react";
import AppDatePicker2 from "../../../Components/AppDatePicker/AppDatePicker2";
import { FaPlus } from "react-icons/fa";
import InlineTable from "../../../Components/antd/Tables/InlineTable";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
const { TabPane } = Tabs;
const _ = require("lodash");
const randomPass = require("secure-random-password");
export default function HoaModal({
  editId,
  initialValues,
  setShowHide,
  afterClose,
  setOpen,
  optionsShow = true,
  isedit = false,
  initailValArr = [],
  deleteHandler = false,
  updateDescription = false,
}) {
  const [getError, setGetError] = useState(false);

  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  const [data, setData] = useState(initailValArr);
  const [hoaListData, setHoaListData] = useState([]);
  const [hoaTypeListData, setHoaTypeListData] = useState([]);
  const [cycleListData, setCycleListData] = useState([]);
  const [read_only_comment, setRead_only_comment] = useState("");
  const [display_oncert_comment, setDisplay_oncert_comment] = useState("");
  const [AccociationRadio, setAccociationRadio] =
    useState("Master Association");
  const [AccociationListData, setAccociationListData] = useState([]);
  const [subdivisionEditList, setSubdivisionEditList] = useState([]);
  const [countyOptions, setCountyOptions] = useState([
    { id: "", county_name: "" },
  ]);

  //calling redux stor for vendors list
  const { token } = useSelector((state) => state.auth);

  //changing tab of address
  const [addressTabKey, setAddressTabKey] = useState("1");

  //   values assign
  const [values, setValues] = useState(initialValues);

  //calling rtk query hook for geting cycle list
  const cycleListApi = useCycleListQuery(token);

  //calling redux stor for hoaType list
  const hoaTypeListApi = useHoaTypeListQuery(token);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }
  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);
  }

  //calling rtk query hook for geting jurisdiction list
  const hoaMgmtList = useHoaMgmtlistQuery(token);

  //calling rtk query hook for geting edit annotation
  const editAnnotationQuery = useEditAnnotationPostMutation();
  const [editAnnotationPost] = editAnnotationQuery;

  //calling rtk query hook for geting edit Hoa data
  const editHomApi = useEditHoaQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //calling rtk query hook for geting edit HOA data
  const updateHoaApi = useUpdateHoaMutation();
  const [updateHoa] = updateHoaApi;

  //calling redux stor for vendors list
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  //calling rtk query hook for adding hoa
  const addHoaApi = useAddHoaMutation();
  const [addHoa] = addHoaApi;
  const DisplayOnCertList = useHoaDisplayOnCertListQuery(token);
  const ReadOnlyList = useHoaReadOnlyListQuery(token);
  //refecth list
  function refecthApi() {
    hoaTypeListApi.refetch();
    cycleListApi.refetch();
    DisplayOnCertList.refetch();
    ReadOnlyList.refetch();
  }

  //calling rtk query hook for geting HOA CO list
  const [HoaSelectList, HoaSelectListResult] = useHoaSelectListMutation();

  //calling rtk query hook for geting HOA CO list
  const [activeSubAssociation, activeSubAssociationResult] =
    useActiveSubAssociationMutation();
  const [activeMasterAssociation, activeMasterAssociationResult] =
    useActiveMasterAssociationMutation();

  // useEffect for activeSubAssociation
  useEffect(() => {
    if (activeSubAssociationResult.isSuccess) {
      if (editId) {
        const newData = activeSubAssociationResult.data?.data?.filter(
          ({ name }) => {
            return name !== values.name;
          }
        );
        setAccociationListData(newData);
      } else {
        setAccociationListData(activeSubAssociationResult.data?.data);
      }

      setValues({
        ...values,
        master_association: [],
        sub_association: [],
      });
    } else {
      setAccociationListData([]);
      setValues({
        ...values,
        master_association: [],
        sub_association: [],
      });
    }
  }, [activeSubAssociationResult.isLoading]);

  // useEffect for activeSubAssociation
  useEffect(() => {
    if (activeMasterAssociationResult.isSuccess) {
      if (editId) {
        const newData = activeMasterAssociationResult.data?.data?.filter(
          ({ name }) => {
            return name !== values.name;
          }
        );
        setAccociationListData(newData);
      } else {
        setAccociationListData(activeMasterAssociationResult.data?.data);
      }

      setValues({
        ...values,
        master_association: [],
        sub_association: [],
      });
    } else {
      setAccociationListData([]);
      setValues({
        ...values,
        master_association: [],
        sub_association: [],
      });
    }
  }, [activeMasterAssociationResult.isLoading]);

  // useEffect for hoc list
  useEffect(() => {
    if (HoaSelectListResult.isSuccess) {
      setHoaListData(HoaSelectListResult.data?.data);
      setValues({
        ...values,
        master_association: [],
        sub_association: [],
      });
    } else {
      setValues({ ...values, hoa_collector_id: "" });
    }
  }, [HoaSelectListResult.isLoading]);

  //useEffect for setting default values
  useEffect(() => {
    setStateData(stateList);
    setCountyOptions([]);
    setValues(initialValues);
    setHoaListData([]);
    setSubdivisionEditList([]);
    //calling function
    refecthApi();
    setRead_only_comment("");
    setDisplay_oncert_comment("");
    //when we edit
    if (!editId) {
      setAddressTabKey("1");
      setAccociationRadio("Master Association");
    }
    // if edit id is exist
  }, [afterClose]);

  //useEffect for edit Hao
  useEffect(() => {
    if (editHomApi.isSuccess) {
      const {
        mailing_zip_five,
        mailing_zip_four,
        physical_zip_five,
        physical_zip_four,
        read_only_comment,
        hoa_subdivisions_list,
        display_oncert_comment,
        sub_association_list,
        master_association_list,
        hoa_collector_id,
        is_master_or_subassociation,
        state_id,
        county_id,
        hoa_collector_state_list,
        ...othersData
      } = editHomApi.data?.data[0];
      var newArr = {
        ...othersData,
        mailing_zip_five: `${mailing_zip_five}${mailing_zip_four ? "-" + mailing_zip_four : ""
          }`,
        physical_zip_five: `${physical_zip_five}${physical_zip_four ? "-" + physical_zip_four : ""
          }`,
        hoa_subdivisions_list,
        add_hoa_subdivisions: [],
        add_hoa_information_comments: [],
        add_hoa_information_notes: [],
        add_hoa_information_management_fees: [],
        add_hoa_information_emails: [],
        edit_hoa_subdivisions: [],
        edit_hoa_information_emails: [],
        edit_hoa_information_management_fees: [],
        edit_hoa_information_comments: [],
        edit_hoa_information_notes:[],
        delete_hoa_information_comments: [],
        delete_hoa_subdivisions: [],
        delete_hoa_information_management_fees: [],
        delete_hoa_information_emails: [],
        delete_hoa_information_notes: [],
        sub_association_list,

        master_association_list,
        state_id,
        county_id,
        is_master: is_master_or_subassociation,
      };
      setSubdivisionEditList(hoa_subdivisions_list);

      getCountyList({ state_id: { state_id: state_id }, token: token });

      setValues({
        ...newArr,
        hoa_collector_id,
      });
      setHoaListData(hoa_collector_state_list);
      setRead_only_comment(read_only_comment);
      setDisplay_oncert_comment(display_oncert_comment);

      if (sub_association_list.length > 0) {
        //req fo sub association list
        activeSubAssociation({
          data: {
            state_id,
            county_id,
          },
          token,
        });

        setAccociationRadio("Master Association");
      } else if (master_association_list.length > 0) {
        setAccociationRadio("Sub Association");
        //req fo mster association list
        activeMasterAssociation({
          data: {
            state_id,
            county_id,
          },
          token,
        });
      }
    }
    if (editHomApi.isError) {
      setGetError(
        editHomApi.error?.data?.error ||
        editHomApi.error?.data?.message ||
        editHomApi.error?.data ||
        (editHomApi.error.error && "server unreachable!") ||
        editHomApi.error.data?.msg.sqlMessage
      );
    }
  }, [editHomApi.isFetching]);

  // useEffect for add hoa
  useEffect(() => {
    if (addHoaApi[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addHoaApi[1].data?.message,
      });
    }
    if (addHoaApi[1].error) {
      setGetError(
        addHoaApi[1].error?.data?.error ||
        addHoaApi[1].error?.data?.message ||
        addHoaApi[1].error.data?.msg?.sqlMessage ||
        (addHoaApi[1].error.error && "server unreachable!") ||
        addHoaApi[1].err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
  }, [addHoaApi[1].isLoading]);

  // useEffect for stateslist
  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    }
    if (countyList2[1].error) {
      setGetError(
        countyList2[1].error?.data?.error ||
        countyList2[1].error?.data?.message ||
        countyList2[1].error.data?.msg?.sqlMessage ||
        (countyList2[1].error.error && "server unreachable!")
      );
    }
  }, [countyList2[1].isLoading]);

  //useEffect for cycle list
  useEffect(() => {
    if (cycleListApi.isSuccess) {
      setCycleListData(asendingList(cycleListApi.data?.data, "name"));
    } else {
      setCycleListData([]);
    }
  }, [cycleListApi.isFetching]);

  // useEffect for hoaType list
  useEffect(() => {
    if (hoaTypeListApi.isSuccess) {
      setHoaTypeListData(
        asendingList(
          hoaTypeListApi.data.data.map(({ name, id }) => {
            return { name, id };
          }),
          "name"
        )
      );
    }
    if (hoaTypeListApi.error) {
      setHoaTypeListData([]);
    }
  }, [hoaTypeListApi.isFetching]);

  //useEffect for updateing Hoa
  useEffect(() => {
    if (updateHoaApi[1].isSuccess) {
      closeModal();
      setOpen(false);
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateHoaApi[1].data?.message,
      });
      if (!optionsShow) {
        //refech aftrer successfull
        hoaMgmtList.refetch();
      }
    }
    if (updateHoaApi[1].error) {
      //here created for in loop for catch server errors

      setGetError(
        updateHoaApi[1].error?.data?.error ||
        updateHoaApi[1].error?.data?.message ||
        (updateHoaApi[1].error.error && "server unreachable!") ||
        updateHoaApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateHoaApi[1].isLoading]);
  //destructure some props

  const inputs = [
    {
      id: 1,
      name: "state_id",
      type: "select",
      value: values.state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      required: true,
      column: "3",
      tab: false,
    },
    {
      id: 2,
      name: "county_id",
      type: "select",
      value: values.county_id || "",
      placeholder: "Select County",
      errorMessage: "Please select county!",
      label: "County",
      options: countyOptions,
      required: true,
      column: "3",
      tab: false,
    },
    {
      id: 3,
      name: "hoa_collector_id",
      type: "select",
      value: values.hoa_collector_id || "",
      placeholder: "Select Hoa Collector",
      errorMessage: "Please select HOA collector!",
      label: "HOA Collector",
      options: asendingList(
        hoaListData?.map(({ name, id }) => {
          return { name, id };
        }),
        "name"
      ),
      column: "3",
      tab: false,
    },
    {
      id: 4,
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      errorMessage: "Please enter name!",
      label: "Name",
      required: true,
      maxLength: 250,
      column: "3",
      tab: false,
    },
    {
      id: 945,
      name: "hoa_type_id",
      type: "select",
      value: values.hoa_type_id || "",
      placeholder: "Select Cycle",
      errorMessage: "Please select Hoa type!",
      label: "HOA Type",
      options: hoaTypeListData || [],
      column: "3",
      required: true,
      tab: false,
    },

    {
      id: 185642,
      name: "fax",
      type: "text",
      _id: "hoa_mgmt_fax",
      column: "3",
      placeholder: "Enter Fax Number",
      errorMessage: "Please enter fax number!",
      label: "Fax",
      maxLength: "10",
      minLength: "5",
    },
    {
      id: 154540,
      name: "phone_number",
      type: "number",
      column: "3",
      _id: "hoa_mgmt_phone_number",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      maxLength: "10",
      minLength: "10",
    },

    {
      id: 5,
      name: "website",
      type: "text",
      value: values?.website || "",
      placeholder: "http://www.website.com",
      errorMessage: "Please enter website!",
      label: "Website",
      maxLength: 250,
      column: "3",
    },
    {
      id: 9,
      name: "cycle_id",
      type: "select",
      value: values.cycle_id || "",
      placeholder: "Select Cycle",
      errorMessage: "Please select collection cycle!",
      label: "Collection Cycle",
      options: cycleListData,
      required: true,
      column: "3",
      tab: false,
    },

    {
      id: 78,
      name: "contact_verified_date",
      type: "date",
      usaFormat: true,
      value: values.contact_verified_date || "",
      errorMessage: "Please select Date Verified!",
      label: "Contact Verify Date",
      column: "3",
    },

    {
      id: 4658,
      name: "is_mailing_address",
      checked: values.is_mailing_address || "",
      type: "switch",
      column: "3",
      label: "Display Mailing Address On Cert",
      margin: "5",
      tooltip:
        "By Default physical address will be displayed on the Certificate",
    },
    {
      id: 6,
      name: "is_self_collected",
      checked: values.is_self_collected || "",
      type: "switch",
      column: "3",
      label: "Self Collected",
      margin: "5",
    },
    {
      id: 4545452,
      name: "is_master",
      _id: "hoa_is_master",
      checked: values.is_master || "",
      type: "checkbox",
      column: `3 ${!values.county_id ? "d-none" : ""}`,
      label: "Master Or Sub Association",
      margin: "3",
    },
    // {
    //   id: 7,
    //   name: "is_association",
    //   type: "radio",
    //   column: `3 ${!values.is_master ? "d-none" : ""}`,
    //   label: "Master Association",
    //   margin: "4",
    // },
    {
      id: 8,
      name: "is_master_or_subassociation",
      type: "radio",
      column: `3 ${!values.is_master ? "d-none" : ""}`,
      label: "Sub Association",
    },

    {
      id: 10,
      name: "mailing_first_name",
      value: values?.mailing_first_name || "",
      type: "text",
      placeholder: "Enter First Name",
      label: "First Name",
      errorMessage: "Please enter first name",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 105656,
      name: "mailing_middle_name",
      value: values?.mailing_middle_name || "",
      type: "text",
      placeholder: "Enter Middle Name",
      label: "Middle Name",
      errorMessage: "Please enter middle name",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 105656,
      name: "mailing_last_name",
      value: values?.mailing_last_name || "",
      type: "text",
      placeholder: "Enter Last Name",
      label: "Last Name",
      errorMessage: "Please enter last name",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 1045650,
      name: "mailing_contact_position",
      value: values?.mailing_contact_position || "",
      type: "text",
      placeholder: "Enter Contact Position",
      errorMessage: "Please enter contact position",
      label: "Contact Position",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 13,
      name: "mailing_address_one",
      value: values?.mailing_address_one || "",
      type: "text",
      placeholder: "Enter Address1",
      errorMessage: "Please enter address1!",
      label: "Address1",
      column: "3",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 14,
      name: "mailing_address_two",
      value: values?.mailing_address_two || "",
      type: "text",
      placeholder: "Enter Address2",
      errorMessage: "Please enter address2!",
      label: "Address2",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 1655,
      name: "mailing_state_id",
      type: "select",
      value: values.mailing_state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      column: "3",
      tab: true,
      address_type: "mailing",
    },
    {
      id: 15,
      name: "mailing_city",
      value: values?.mailing_city || "",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 16,
      name: "mailing_zip_five",
      value: values?.mailing_zip_five || "",
      type: "text",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
      address_type: "mailing",
      tab: true,
    },

    {
      id: 11,
      name: "mailing_phone_number",
      type: "number",
      value: values.mailing_phone_number,
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 12,
      name: "mailing_fax",
      type: "text",
      value: values.mailing_fax,
      placeholder: "Enter Fax",
      errorMessage: "Please enter 10 digits fax number!",
      label: "Fax",
      column: "3",
      maxLength: 10,
      minLength: 10,
      address_type: "mailing",
      tab: true,
    },
    {
      id: 115612,
      name: "mailing_email",
      type: "text",
      placeholder: "Enter Email",
      value: values.mailing_email || "",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      address_type: "mailing",
      tab: true,
    },
    {
      id: 4545452,
      name: "is_mailing_address_flag",
      _id: "hoa_mgmt_is_mailing_address_flag",
      checked: values.is_mailing_address_flag || "",
      type: "checkbox",
      column: "4",
      label: "Is Mailing Address Same As Physical Address ",
      margin: "3",
      address_type: "mailing",
      tab: true,
    },
    //physical
    {
      id: 454510,
      name: "physical_first_name",
      value: values?.physical_first_name || "",
      errorMessage: "Please enter first name!",
      type: "text",
      placeholder: "Enter First Name",
      label: "First Name",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 450,
      name: "physical_middle_name",
      value: values?.physical_middle_name || "",
      errorMessage: "Please enter middle name!",
      type: "text",
      placeholder: "Enter Middle Name",
      label: "Middle Name",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 450545,
      name: "physical_last_name",
      value: values?.physical_last_name || "",
      errorMessage: "Please enter last name!",
      type: "text",
      placeholder: "Enter Last Name",
      label: "Last Name",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 110,
      name: "physical_contact_position",
      value: values?.physical_contact_position || "",
      type: "text",
      placeholder: "Enter Contact Position",
      errorMessage: "Please enter contact position",
      label: "Contact Position",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 13,
      name: "physical_address_one",
      value: values?.physical_address_one || "",
      type: "text",
      placeholder: "Enter Address1",
      errorMessage: "Please enter address1!",
      label: "Address1",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 14,
      name: "physical_address_two",
      value: values?.physical_address_two || "",
      type: "text",
      placeholder: "Enter Address2",
      errorMessage: "Please enter address2!",
      label: "Address2",
      column: "3",
      address_type: "physical",
      tab: true,
    },
    {
      id: 1655,
      name: "physical_state_id",
      type: "select",
      value: values.physical_state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      column: "3",
      tab: true,
      address_type: "physical",
    },
    {
      id: 15,
      name: "physical_city",
      value: values?.physical_city || "",
      type: "text",
      placeholder: "Enter City",
      errorMessage: "Please enter city!",
      label: "City",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 16,
      name: "physical_zip_five",
      value: values?.physical_zip_five || "",
      type: "text",
      column: "3",
      placeholder: "Enter Zip Code",
      errorMessage: "Zip code must be min 5 digits!",
      label: "Zip Code",
      maxLength: "10",
      minLength: "5",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 11645,
      name: "physical_phone_number",
      type: "number",
      value: values.physical_phone_number || "",
      placeholder: "Enter Phone Number",
      errorMessage: "Please enter 10 digits phone number!",
      label: "Phone Number",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },
    {
      id: 12,
      name: "physical_fax",
      type: "text",
      value: values?.physical_fax || "",
      placeholder: "Enter Fax",
      errorMessage: "Please enter 10 digits fax number!",
      label: "Fax",
      column: "3",
      maxLength: 10,
      minLength: 10,
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 11845,
      name: "physical_email",
      type: "text",
      value: values.physical_email || "",
      placeholder: "Enter Email",
      errorMessage: "Please enter valid email!",
      label: "Email",
      column: "3",
      address_type: "physical",
      tab: true,
      // required: is_mailing_address === 0 ? false : true,
    },

    {
      id: 454454556,
      name: "is_mailing_address_flag",
      _id: "hoa_mgmt_is_mailing_address_flag",
      checked: values.is_mailing_address_flag || "",
      type: "checkbox",
      column: "4",
      label: "Is Mailing Address Same As Physical Address ",
      margin: "3",
      address_type: "physical",
      tab: true,
    },
  ];

  const cols = [
    {
      title: "Notes",
      dataIndex: "fees",
      key: "fees",
      type: "text",
      align: "center",
      render: (value) => value || <>&nbsp;</>,
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
 
    
   

    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      align: "center",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const onChange = async (e, date, dateFormat) => {
    if (dateFormat) {
      setValues({
        ...values,
        [e]: date,
      });
    } else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
      if (name === "mailing_fax" || name === "fax" || name === "physical_fax") {
        setValues({
          ...values,
          [name]: value.replace(/[^0-9]/gi, ""),
        });
      } else if (name === "mailing_zip_five") {
        const value1 = value.replace(/[^0-9]/g, "");
        var foo = value1.split("-").join("");
        if (foo.length > 0) {
          foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
        }
        // value1.replace(/(\d{5})/, "$1-")
        setValues({ ...values, mailing_zip_five: foo });
      } else if (name === "physical_zip_five") {
        const value1 = value.replace(/[^0-9]/g, "");
        var foo = value1.split("-").join("");
        if (foo.length > 0) {
          foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
        }
        // value1.replace(/(\d{5})/, "$1-")
        setValues({ ...values, physical_zip_five: foo });
      }
    }

    //clear Status
    clearStatus();
  };

  //select handler
  const selectHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: Number(value) });

    if (property === "state_id") {
      setAccociationRadio("Master Association");

      const state_id = { state_id: Number(value) };
      if (value) {
        getCountyList({ state_id, token: token });
        //hoc list
        HoaSelectList({
          id: value,
          token,
        });
        setValues({
          ...values,
          [property]: Number(value),
          county_id: "",
          hoa_collector_id: "",
          hoa_subdivisions_list: [],
          add_hoa_subdivisions: [],
          edit_hoa_subdivisions: [],
          master_association: [],
          sub_association: [],
          master_association_list: [],
          sub_association_list: [],
        });
      } else {
        setValues({
          ...values,
          [property]: Number(value),
          county_id: "",
          hoa_collector_id: "",
          hoa_subdivisions_list: [],
          add_hoa_subdivisions: [],
          edit_hoa_subdivisions: [],
          master_association: [],
          sub_association: [],
          master_association_list: [],
          sub_association_list: [],
        });
        setCountyOptions([]);
        setHoaListData([]);
      }
    }
    //county id
    else if (property === "county_id") {
      setAccociationRadio("Master Association");
      if (!value) {
        setValues({
          ...values,
          is_master: false,
          county_id: value,
          master_association: [],
          sub_association: [],
          master_association_list: [],
          sub_association_list: [],
        });
      } else {
        setValues({
          ...values,
          county_id: value,
          master_association: [],
          sub_association: [],
          master_association_list: [],
          sub_association_list: [],
        });
        //if is_master on
        if (values.is_master) {
          activeSubAssociation({
            data: {
              state_id: values.state_id,
              county_id: value,
            },
            token,
          });
        }
      }
    }

    if (property === "annotation_id" && value) {
      editAnnotationPost({ id: Number(value), token });
    } else if (property === "annotation_id" && !value) {
      setValues({ ...values, annotation_id: "", comments: "" });
    }
  };

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
    clearStatus();
  };

  //tab chage
  const TabOnChange = (key) => {
    setAddressTabKey(key);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    // if (editId) {
    //   editUserFetch.refetch();
    // }
    clearStatus();
    setRead_only_comment("");
    setDisplay_oncert_comment("");
  };

  const handleSubmit = (e) => {
    values.is_master_or_subassociation = values.is_master;
    values.is_master =
      AccociationRadio === "Master Association" &&
        values.is_master_or_subassociation
        ? true
        : false;
    values.is_association =
      AccociationRadio === "Sub Association" &&
        values.is_master_or_subassociation
        ? true
        : false;

    // values.contact_verified_date = dateFormat(values.contact_verified_date)

    e.preventDefault();
    if (values.phone_number?.length !== 12 && values.phone_number?.length > 0) {
      setGetError("Please enter valid phone number");
    } else if (
      values.mailing_phone_number?.length !== 12 &&
      values.mailing_phone_number?.length > 0
    ) {
      setGetError("Please enter valid mailing phone number");
    } else if (
      values.physical_phone_number?.length !== 12 &&
      values.physical_phone_number?.length > 0
    ) {
      setGetError("Please enter valid physical phone number");
    }
    // else if (
    //   values.is_master &&
    //   values.sub_association.length===0 && AccociationRadio === "Master Association"
    // ) {
    //   setGetError("Please add Sub Association");
    // }
    // else if (
    //   values.is_master &&
    //   values.master_association.length===0 && AccociationRadio === "Sub Association"
    // ) {
    //   setGetError("Please add Master Association");
    // }
    else {
      removeBlank(values);
      const mailing_zip_five = values.mailing_zip_five?.split("-");
      const physical_zip_five = values.physical_zip_five?.split("-");
      // if edit id not exists then add hoa

      if (!editId) {

        // console.log({
        //   ...values,
        //   mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
        //   mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
        //   physical_zip_five: physical_zip_five && physical_zip_five[0],
        //   physical_zip_four:
        //     physical_zip_five && (physical_zip_five[1] || null),
        //   read_only_comment,
        //   display_oncert_comment,
        // });
        addHoa({
          data: {
            ...values,
            mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
            mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
            physical_zip_five: physical_zip_five && physical_zip_five[0],
            physical_zip_four:
              physical_zip_five && (physical_zip_five[1] || null),
            read_only_comment,
            display_oncert_comment,
          },
          token: token,
        });
      }

      if (editId) {
        const deleteCountyBaseOnChange = subdivisionEditList?.filter(
          ({ county_id }) => {
            return returnArryObjID(countyOptions, "id").includes(county_id);
          }
        );

        var deletedValues = values.delete_hoa_subdivisions;
        if (deleteCountyBaseOnChange.length === 0) {
          subdivisionEditList?.map(({ id }) => {
            deletedValues.push(id);
          });
          values.edit_hoa_subdivisions = [];
        }

        // console.log({
        //   ...values,
        //   mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
        //   mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
        //   physical_zip_five: physical_zip_five && physical_zip_five[0],
        //   physical_zip_four:
        //     physical_zip_five && (physical_zip_five[1] || null),
        //   delete_hoa_subdivisions: _.uniq(deletedValues),
        //   read_only_comment,
        //   display_oncert_comment,
        // })
        updateHoa({
          data: {
            ...values,
            mailing_zip_five: mailing_zip_five && mailing_zip_five[0],
            mailing_zip_four: mailing_zip_five && (mailing_zip_five[1] || null),
            physical_zip_five: physical_zip_five && physical_zip_five[0],
            physical_zip_four:
              physical_zip_five && (physical_zip_five[1] || null),
            delete_hoa_subdivisions: _.uniq(deletedValues),
            read_only_comment,
            display_oncert_comment,
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  //useEffect for editCustomer refecth
  useEffect(() => {
    if (editId) {
      editHomApi.refetch();
    }
    clearStatus();
  }, [afterClose]);

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("hoaError");
    }
  }, [getError]);

  //check bbox
  const appCheckboxHandler = (property, value) => {
    clearStatus();

    const {
      mailing_fax,
      mailing_contact_position,
      mailing_first_name,
      mailing_last_name,
      mailing_middle_name,
      mailing_phone_number,
      mailing_email,
      mailing_address_one,
      mailing_address_two,
      mailing_state_id,
      mailing_city,
      mailing_zip_five,
    } = values;

    if (value) {
      setValues({
        ...values,
        physical_phone_number: mailing_phone_number,
        physical_email: mailing_email,
        physical_address_one: mailing_address_one,
        physical_address_two: mailing_address_two,
        physical_state_id: mailing_state_id,
        physical_city: mailing_city,
        physical_zip_five: mailing_zip_five,
        physical_first_name: mailing_first_name,
        physical_last_name: mailing_last_name,
        physical_middle_name: mailing_middle_name,
        physical_contact_position: mailing_contact_position,
        physical_fax: mailing_fax,
        [property]: value,
        is_association: false,
        is_master_or_subassociation: false,
      });
    } else {
      setValues({
        ...values,
        physical_phone_number: "",
        physical_email: "",
        physical_address_one: "",
        physical_address_two: "",
        physical_state_id: "",
        physical_city: "",
        physical_zip_five: "",
        physical_zip_four: "",
        physical_first_name: "",
        physical_middle_name: "",
        physical_last_name: "",
        physical_contact_position: "",
        physical_fax: "",
        [property]: value,
        is_association: false,
        is_master_or_subassociation: false,
      });
    }

    if (property === "is_master") {
      setValues({
        ...values,
        is_master: value,
        master_association_list: [],
        sub_association_list: [],
        master_association: [],
        sub_association: [],
      });
      setAccociationRadio("Master Association");
      if (value) {
        //req to active sub association list
        activeSubAssociation({
          data: {
            state_id: values.state_id,
            county_id: values.county_id,
          },
          token,
        });
      } else {
        setAccociationListData([]);
      }
    }
  };

  const handleDelete = (record) => {
    deleteHandler && deleteHandler(record);
    const newData = data?.filter(
      (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
    );
    setData(newData);
  };

  const data1 = useMemo(() => data, [data]);

  //creating comp fo shwoing list of sub or mas assocition
  const activeSubOrAssocian = useCallback(() => {
    return (
      <div className="col-12 col-md-3 ">
        <MultiCheckList
          placeholder={
            AccociationRadio === "Master Association"
              ? "Sub Association"
              : "Master Association"
          }
          heading={`Active ${AccociationRadio === "Master Association"
            ? "Sub Association"
            : "Master Association"
            }`}
          afterClose={afterClose}
          getSelectVal={(value) => {
            setGetError(false);
            setValues({
              ...values,
              sub_association:
                AccociationRadio === "Master Association" ? value : [],
              master_association:
                AccociationRadio === "Master Association" ? [] : value,
            });
          }}
          plainOptions={AccociationListData}
          currentValues={AccociationRadio}
          clearValues={
            activeMasterAssociationResult.isLoading ||
            activeSubAssociationResult.isLoading
          }
          defaultCheckedList={
            editId && values.master_association_list?.length > 0
              ? values.master_association_list
              : values.sub_association_list
          }
        />
      </div>
    );
  }, [AccociationListData, AccociationRadio, editHomApi.isFetching]);

  const handleAdd = () => {
    const randomId = randomPass.randomPassword({
      length: 9,
      characters: randomPass.digits,
    });

    const newData = {
      tempId: randomId,
      hoa_fees_type: "",
    };
    setData([...data, newData]);
    updateDescription && updateDescription(newData);
  };

  const oneEditFun = useCallback(
    async (value, id, type) => {
      removeBlank(value);
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { hoa_fees_type_id, ...rest } = value;
        if (type === "select") {
          updateDescription && updateDescription(rest);
        } else if (type !== "select") {
          updateDescription &&
            updateDescription({
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data, updateDescription]
  );

  return (
    <div className="position-relative mytab">
      <div className=" border-bottom border-2 " id="hoaError">
        <h5>{editId ? "Edit HOA" : "Add HOA"}</h5>
      </div>
      <div className="mt-3">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>

      <form onSubmit={handleSubmit} action="">
        {(countyList2[1].isLoading ||
          editAnnotationQuery[1].isLoading ||
          updateHoaApi[1].isLoading ||
          addHoaApi[1].isLoading ||
          activeMasterAssociationResult.isLoading ||
          activeSubAssociationResult.isLoading ||
          editHomApi.isFetching) && <AppSpinner />}

        <div className={`${editHomApi.isFetching ? "invisible" : ""} `}>
          <div className="row">
            {/* //creating dynamic style  */}
            <div className={`col-12 col-md-${values.is_master ? "9" : "12"}`}>
              <Row>
                {inputs.map((input) => (
                  <>
                    {input.type === "radio" && values.is_master && (
                      <div className="col-12 col-md-6 ">
                        <Radio.Group
                          className="mt-3"
                          onChange={(e) => {
                            const { value } = e.target;
                            setGetError(false);
                            setValues({
                              ...values,
                              master_association: [],
                              sub_association: [],
                              master_association_list: [],
                              sub_association_list: [],
                            });
                            setAccociationRadio(value);
                            if (value === "Master Association") {
                              //req fo sub association list
                              activeSubAssociation({
                                data: {
                                  state_id: values.state_id,
                                  county_id: values.county_id,
                                },
                                token,
                              });
                            } else {
                              //req fo master association list
                              activeMasterAssociation({
                                data: {
                                  state_id: values.state_id,
                                  county_id: values.county_id,
                                },
                                token,
                              });
                            }
                          }}
                          value={AccociationRadio || ""}
                        >
                          <Radio value={"Master Association"}>
                            Master Association
                          </Radio>
                          <Radio value={"Sub Association"}>
                            Sub Association
                          </Radio>
                        </Radio.Group>
                      </div>
                    )}
                    {/* {input.type === "date" && <AppDatePicker2 defaultSet={afterClose} {...input} onChange={(value) => setValues({ ...values, [input.name]: value })} />} */}
                    {(input.type === "text" ||
                      input.type === "email" ||
                      input.type === "date"
                    ) &&
                      input.type !== "number" &&
                      !input.tab && (
                        <AppInput
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      )}
                    {input.type === "number" && !input.address_type && (
                      <div className="col-12 col-md-3">
                        <Row className="mt-3">
                          <label className="ms-1 heading-5 text-dark ">
                            {input.label}
                          </label>
                        </Row>
                        <Input
                          className="input-field mt-0 pb-2 w-100 heading-5"
                          maxLength={14}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          minLength={14}
                          placeholder={input.placeholder}
                          defaultCountry="US"
                          value={values[input.name]}
                          onChange={(value) => {
                            setValues({ ...values, [input.name]: value });
                            setGetError(false);
                          }}
                        />
                      </div>
                    )}
                    {/* //appSelcet for state  */}
                    {input.type === "select" &&
                      input.name === "state_id" &&
                      input.tab === false && (
                        //base on user field flags
                        <AppSelect
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {/* //appSelcet for state  */}
                    {input.type === "select" &&
                      input.name === "hoa_collector_id" &&
                      input.tab === false && (
                        //base on user field flags
                        <AppSelect
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select HOA collector</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                    {/* //appSelcet for county  */}
                    {input.type === "select" &&
                      input.name === "county_id" &&
                      input.tab === false && (
                        //base on user field flags
                        <AppSelect
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select County</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.county_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
                    {/* //appSelcet for cycle  */}
                    {input.type === "select" &&
                      input.name === "cycle_id" &&
                      input.tab === false && (
                        //base on user field flags
                        <AppSelect
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>
                                Select Collection Cycle
                              </option>
                              {input.options.length > 0 &&
                                input.options.map((ele) => {
                                  return (
                                    <option key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </option>
                                  );
                                })}
                            </>
                          }
                        />
                      )}
                    {/* //appSelcet for cycle  */}
                    {input.type === "select" &&
                      input.name === "hoa_type_id" &&
                      input.tab === false && (
                        //base on user field flags
                        <AppSelect
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select Hoa Type</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                    {input.type === "switch" && !input?.tab && (
                      <AppSwitch
                        key={input.id}
                        onChange={appSwitchHanler}
                        {...input}
                      />
                    )}
                    {input.type === "checkbox" && !input.tab && (
                      <AppCheckbox
                        {...input}
                        handleChange={appCheckboxHandler}
                      />
                    )}
                  </>
                ))}
              </Row>
            </div>
            {values.is_master && activeSubOrAssocian()}
          </div>

          {!editHomApi.isFetching && (
            <div className="row">
              <div className="col-12 px-4">
                <div className="mt-3 ">
                  {(editId ? !countyList2[1].isLoading : true) && (
                    <div className="row">
                      <MultiEmails
                        afterClose={afterClose}
                        addFormVal={(value) => {
                          if (!editId) {
                            values.hoa_information_emails = value;
                          }
                        }}
                        //add new
                        whileEdit={(value) => {
                          if (editId) {
                            setValues({
                              ...values,
                              add_hoa_information_emails: [
                                ...values?.add_hoa_information_emails,
                                value,
                              ],
                            });
                          }
                        }}
                        //update
                        updateContact={(value) => {
                          if (editId) {
                            if (
                              values.add_hoa_information_emails?.length > 0 &&
                              values.add_hoa_information_emails?.find(
                                (val) => val["temp_id"] === value.temp_id
                              )
                            ) {
                              values.add_hoa_information_emails.forEach(
                                (item) => {
                                  if (item.temp_id === value.temp_id) {
                                    item.email = value.email;
                                  }
                                }
                              );
                            }

                            if (
                              values.edit_hoa_information_emails?.length > 0 &&
                              values.edit_hoa_information_emails?.find(
                                (val) => val["id"] === value.id
                              )
                            ) {
                              values.edit_hoa_information_emails.forEach(
                                (item) => {
                                  if (item.id === value.id) {
                                    item.email = value.email;
                                  }
                                }
                              );
                            } else if (value?.id) {
                              setValues({
                                ...values,
                                edit_hoa_information_emails: [
                                  ...values?.edit_hoa_information_emails,
                                  value,
                                ],
                              });
                            }
                          }
                        }}
                        //delete
                        deleteContact={(value) => {
                          if (editId) {
                            if (value?.id) {
                              setValues({
                                ...values,
                                delete_hoa_information_emails: [
                                  ...values?.delete_hoa_information_emails,
                                  String(value.id),
                                ],
                              });
                            } else {
                              setValues({
                                ...values,
                                add_hoa_information_emails:
                                  values.add_hoa_information_emails?.filter(
                                    (e) => {
                                      return e.temp_id !== value.temp_id;
                                    }
                                  ),
                              });
                            }
                          }
                        }}
                        initailValArr={
                          editId ? values?.hoa_information_emails_list : []
                        }
                      />
                    </div>
                  )}
                  {!countyList2[1].isLoading && (
                    <div className="row my-2">
                      <MultiSubdivisions
                        afterClose={afterClose}
                        countyList={countyOptions}
                        addFormVal={(value) => {
                          if (!editId) {
                            setValues({ ...values, hoa_subdivisions: value });
                          }
                        }}
                        //add new
                        whileEdit={(value) => {
                          if (editId) {
                            setValues({
                              ...values,
                              add_hoa_subdivisions: [
                                ...values?.add_hoa_subdivisions,
                                value,
                              ],
                            });
                          }
                        }}
                        //update
                        updateContact={(value) => {
                          if (editId) {
                            if (
                              values.add_hoa_subdivisions?.length > 0 &&
                              values.add_hoa_subdivisions?.find(
                                (val) => val["temp_id"] === value.temp_id
                              )
                            ) {
                              values.add_hoa_subdivisions.forEach((item) => {
                                if (item.temp_id === value.temp_id) {
                                  item.code = value.code;
                                  item.county_id = value.county_id;
                                  item.county_id = value.county_id;
                                  item.keyword = value.keyword;
                                  item.name = value.name;
                                }
                              });
                            }

                            if (
                              values.edit_hoa_subdivisions?.length > 0 &&
                              values.edit_hoa_subdivisions?.find(
                                (val) => val["id"] === value.id
                              )
                            ) {
                              values.edit_hoa_subdivisions.forEach((item) => {
                                if (item.id === value.id) {
                                  item.code = value.code;
                                  item.county_id = value.county_id;
                                  item.county_id = value.county_id;
                                  item.keyword = value.keyword;
                                  item.name = value.name;
                                }
                              });
                            } else {
                              setValues({
                                ...values,
                                edit_hoa_subdivisions: [
                                  ...values?.edit_hoa_subdivisions,
                                  value,
                                ],
                              });
                            }
                          }
                        }}
                        //delete
                        deleteContact={(value) => {
                          if (editId) {
                            if (value?.id) {
                              setValues({
                                ...values,
                                delete_hoa_subdivisions: [
                                  ...values?.delete_hoa_subdivisions,
                                  String(value.id),
                                ],
                              });
                            } else {
                              setValues({
                                ...values,
                                add_hoa_subdivisions:
                                  values.add_hoa_subdivisions?.filter((e) => {
                                    return e.temp_id !== value.temp_id;
                                  }),
                              });
                            }
                          }
                        }}
                        initailValArr={
                          editId ? values?.hoa_subdivisions_list : []
                        }
                      />
                    </div>
                  )}
                  {(editId ? !countyList2[1].isLoading : true) && (
                    <div className="row my-1">
                      <MultiManagement
                        setDate={editId ? values.hoa_verified_date : ""}
                        //getting date info
                        getDate={(value) => {
                          setValues({ ...values, hoa_verified_date: value })
                        }}
                        afterClose={afterClose}
                        addFormVal={(value) => {
                          values.hoa_information_management_fees = value;
                        }}
                        //add new
                        whileEdit={(value) => {
                          if (editId) {
                            setValues({
                              ...values,
                              add_hoa_information_management_fees: [
                                ...values?.add_hoa_information_management_fees,
                                value,
                              ],
                            });
                          }
                        }}
                        //update
                        updateContact={(value) => {
                          if (editId) {
                            if (
                              values.add_hoa_information_management_fees
                                ?.length > 0 &&
                              values.add_hoa_information_management_fees?.find(
                                (val) => val["temp_id"] === value.temp_id
                              )
                            ) {
                              values.add_hoa_information_management_fees.forEach(
                                (item) => {
                                  if (item.temp_id === value.temp_id) {
                                    item.description = value.description;
                                    item.fees = value.fees;
                                  }
                                }
                              );
                            }
                            if (
                              values.edit_hoa_information_management_fees
                                ?.length > 0 &&
                              values.edit_hoa_information_management_fees?.find(
                                (val) => val["id"] === value.id
                              )
                            ) {
                              values.edit_hoa_information_management_fees.forEach(
                                (item) => {
                                  if (item.id === value.id) {
                                    item.description = value.description;
                                    item.fees = value.fees;
                                  }
                                }
                              );
                            } else if (value?.id) {
                              setValues({
                                ...values,
                                edit_hoa_information_management_fees: [
                                  ...values?.edit_hoa_information_management_fees,
                                  value,
                                ],
                              });
                            }
                          }
                        }}
                        //delete
                        deleteContact={(value) => {
                          if (editId) {
                            if (value?.id) {
                              setValues({
                                ...values,
                                delete_hoa_information_management_fees: [
                                  ...values?.delete_hoa_information_management_fees,
                                  String(value.id),
                                ],
                              });
                            } else {
                              setValues({
                                ...values,
                                add_hoa_information_management_fees:
                                  values.add_hoa_information_management_fees?.filter(
                                    (e) => {
                                      return e.temp_id !== value.temp_id;
                                    }
                                  ),
                              });
                            }
                          }
                        }}
                        initailValArr={
                          editId
                            ? values?.hoa_information_management_fees_list
                            : []
                        }
                      />
                    </div>
                  )}
                  <div className="row my-1">
                    <Tabs
                      className="w-100 border p-2"
                      defaultActiveKey={String(addressTabKey)}
                      onChange={TabOnChange}
                      type="card"
                    >
                      {/* //physical address  */}
                      <TabPane tab="Mailing" key="1">
                        <div className="row">
                          {inputs.map((input) => (
                            <>
                              {(input.type === "text" ||
                                input.type === "email") &&
                                input.tab === true &&
                                input?.address_type === "mailing" && (
                                  <AppInput
                                    key={input.id}
                                    defaultSet={afterClose}
                                    {...input}
                                    onChange={onChange}
                                  />
                                )}
                              {input.type === "number" &&
                                input.tab === true &&
                                input?.address_type === "mailing" && (
                                  <div className="col-12 col-md-3">
                                    <Row className="mt-3">
                                      <label className="ms-1 heading-5 text-dark ">
                                        {input.label}
                                      </label>
                                    </Row>
                                    <Input
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                      className="input-field mt-0 pb-2 w-100 heading-5"
                                      maxLength={14}
                                      minLength={14}
                                      placeholder={input.placeholder}
                                      defaultCountry="US"
                                      value={values[input.name]}
                                      onChange={(value) => {
                                        setValues({
                                          ...values,
                                          [input.name]: value,
                                        });
                                        setGetError(false);
                                      }}
                                    />
                                  </div>
                                )}
                              {/* //appSelcet for state name  */}

                              {input.type === "select" &&
                                input.name === "mailing_state_id" &&
                                input.tab === true &&
                                input?.address_type === "mailing" && (
                                  <AppSelect
                                    key={input.id}
                                    {...input}
                                    onChange={selectHandler}
                                    options={
                                      <>
                                        <option value={""}>Select State</option>

                                        {input.options.map((ele) => {
                                          return (
                                            <option key={ele.id} value={ele.id}>
                                              {ele.state_name}
                                            </option>
                                          );
                                        })}
                                      </>
                                    }
                                  />
                                )}
                              {input.type === "checkbox" &&
                                !editId &&
                                input.tab === true &&
                                input?.address_type === "mailing" && (
                                  <AppCheckbox
                                    {...input}
                                    handleChange={appCheckboxHandler}
                                  />
                                )}
                              {input.type === "switch" &&
                                input.tab === true &&
                                input?.address_type === "mailing" && (
                                  <AppSwitch
                                    key={input.id}
                                    value={values[input.name]}
                                    onChange={appSwitchHanler}
                                    {...input}
                                  />
                                )}
                            </>
                          ))}
                        </div>
                      </TabPane>
                      {/* //physical address  */}
                      {/* <TabPane tab="Physical" key="2">
                        <div className="row">
                          {inputs.map((input) => (
                            <>
                              {(input.type === "text" ||
                                input.type === "email") &&
                                input.tab === true &&
                                input?.address_type === "physical" && (
                                  <AppInput
                                    key={input.id}
                                    defaultSet={afterClose}
                                    {...input}
                                  

                                    onChange={onChange}
                                  />
                                )}
                              {input.type === "number" &&
                                input.tab === true &&
                                input?.address_type === "physical" && (
                                  <div className="col-12 col-md-3">
                                    <Row className="mt-3">
                                      <label className="ms-1 heading-5 text-dark ">
                                        {input.label}
                                      </label>
                                    </Row>
                                    <Input
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                      className="input-field mt-0 pb-2 w-100 heading-5"
                                      maxLength={14}
                                      minLength={14}
                                      placeholder={input.placeholder}
                                      defaultCountry="US"
                                      value={values[input.name]}
                                      onChange={(value) => {
                                        setValues({
                                          ...values,
                                          [input.name]: value,
                                        });
                                        setGetError(false);
                                      }}
                                    />
                                  </div>
                                )}
                            

                              {input.type === "select" &&
                                input.name === "physical_state_id" &&
                                input.tab === true &&
                                input?.address_type === "physical" && (
                                  <AppSelect
                                    key={input.id}
                                    {...input}
                                    onChange={selectHandler}
                                    options={
                                      <>
                                        <option value={""}>Select State</option>
                                        {input.options.map((ele) => {
                                          return (
                                            <option key={ele.id} value={ele.id}>
                                              {ele.state_name}
                                            </option>
                                          );
                                        })}
                                      </>
                                    }
                                  />
                                )}

                              {input.type === "switch" &&
                                input.tab === true &&
                                input?.address_type === "physical" && (
                                  <AppSwitch
                                    key={input.id}
                                    value={values[input.name]}
                                    onChange={appSwitchHanler}
                                    {...input}
                                  />
                                )}
                              {input.type === "checkbox" &&
                                !editId &&
                                input.tab === true &&
                                input?.address_type === "physical" && (
                                  <AppCheckbox
                                    {...input}
                                    handleChange={appCheckboxHandler}
                                  />
                                )}
                            </>
                          ))}
                        </div>
                      </TabPane> */}

                      {/* //Annotations  */}

                      {/* <TabPane tab="Comments" key="3">
                        <div className="row editor">
                          <div className="col-12 col-lg-6 px-2 ">
                            {!DisplayOnCertList.isLoading && (
                              <div className="row align-items-center">
                                <div className="col-12 col-lg-3">
                                  <h6>Display On Cert</h6>
                                </div>
                                <div className="col-12 col-lg-9">
                                  <AppSelect
                                    value={values.selDisplay}
                                    classStyle="border w-100 p-1 my-2"
                                    onChange={(_, value) => {
                                      setValues({
                                        ...values,
                                        selDisplay: value,
                                      });
                                      setDisplay_oncert_comment(
                                        display_oncert_comment + value
                                      );
                                    }}
                                    options={
                                      <>
                                        <option value={""}>
                                          Select Display On Cert
                                        </option>
                                        {DisplayOnCertList.isSuccess &&
                                          DisplayOnCertList?.data?.data?.map(
                                            ({ title, comments, id }) => {
                                              return (
                                                <option
                                                  key={id}
                                                  value={comments}
                                                >
                                                  {title}
                                                </option>
                                              );
                                            }
                                          )}
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <AppTextEditor
                              value={display_oncert_comment}
                              onChange={(newContent) =>
                                setDisplay_oncert_comment(newContent)
                              }
                            />
                          </div>
                          <div className="col-12 col-lg-6 px-2 ">
                            {!ReadOnlyList.isLoading && (
                              <div className="row align-items-center">
                                <div className="col-12 col-lg-3">
                                  <h6>Read Only</h6>
                                </div>
                                <div className="col-12 col-lg-9 ">
                                  <AppSelect
                                    value={values.selReadOnly}
                                    classStyle="border w-100 p-1 my-2"
                                    onChange={(_, value) => {
                                      setValues({
                                        ...values,
                                        selReadOnly: value,
                                      });
                                      setRead_only_comment(
                                        read_only_comment + value
                                      );
                                    }}
                                    options={
                                      <>
                                        <option value={""}>
                                          Select Read Only
                                        </option>
                                        {ReadOnlyList.isSuccess &&
                                          ReadOnlyList?.data?.data.map(
                                            ({ title, comments, id }) => {
                                              return (
                                                <option
                                                  key={id}
                                                  value={comments}
                                                >
                                                  {title}
                                                </option>
                                              );
                                            }
                                          )}
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <AppTextEditor
                              value={read_only_comment}
                              onChange={(newContent) =>
                                setRead_only_comment(newContent)
                              }
                            />
                          </div>
                        </div>
                      </TabPane> */}
                      <TabPane tab='Notes' key="2">
                      {/* <div className="gray-table-col  mb-2 p-2 "> */}
                        {/* <div
                          className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
                        >
                        
                          {!isedit && (
                            <div className={`add_icon ms-auto`} onClick={handleAdd}>
                              <FaPlus />
                            </div>
                          )} */}
                        {/* </div> */}
                        
                        <MultiNotes
                           afterClose={afterClose}
                           addFormVal={(value) => {
                               values.hoa_information_notes = value;
                           }}
                           //add new
                            whileEdit={(value) => {
                              if (editId) {
                                 setValues({
                                    ...values,
                                    add_hoa_information_notes: [
                                    ...values?.add_hoa_information_notes,
                                 value,
                              ],
                            });
                          }
                        }}
                        //update
                        updateContact={(value) => {
                          if (value?.temp_id) {
                            if (
                              values.add_hoa_information_notes?.length > 0 &&
                              values.add_hoa_information_notes?.find(
                                (val) => val["temp_id"] === value.temp_id
                              )
                            ) {
                              values.add_hoa_information_notes.forEach((item) => {
                                if (item.temp_id === value.temp_id) {
                                  item.note = value.note
                                }
                              });
                            }
                          }
  
                          if (editId) {
                            if (
                              values.edit_hoa_information_notes?.length > 0 &&
                              values.edit_hoa_information_notes?.find(
                                (val) => val["id"] === value.id
                              )
                            ) {
                              values.edit_hoa_information_notes.forEach(
                                (item) => {
                                  if (item.id === value.id) {
                                    item.note = value.note
                                  }
                                }
                              );
                            } else {
                              if (value?.id) {
                                setValues({
                                  ...values,
                                  edit_hoa_information_notes: [
                                    ...values?.edit_hoa_information_notes,
                                    {...value, indexes: String(value?.indexes), is_active:Boolean(value?.is_active), is_mandatory: Boolean(value?.is_mandatory)},
                                  ],
                                });
                              }
                            }
                          }
                        }}
                        //delete
                        deleteContact={(value) => {
                          console.log(editId, value)
                          if (editId) {
                            if (value?.id) {
                              setValues({
                                ...values,
                                delete_hoa_information_notes: [
                                  ...values?.delete_hoa_information_notes,
                                  String(value.id),
                                ],
                              });
                            } else {
                              setValues({
                                ...values,
                                add_hoa_information_notes:
                                  values.add_hoa_information_notes?.filter(
                                    (e) => {
                                      return e.temp_id !== value.temp_id;
                                    }
                                  ),
                              });
                            }
                          }
                        }}
                        initailValArr={
                          editId ? values?.hoa_information_notes : []
                        }
                        />
                        {/* <div className="bg-white shadow-sm rounded border">
                          <InlineTable
                            onEdit={oneEditFun}
                            setData={setData}
                            data={data1}
                            cols={cols}
                          />
                        </div> */}
                      {/* </div> */}
                      
                      </TabPane>
                    </Tabs>
                    
                  </div>

                  {/* //multibranches  */}

                  <div className="d-flex justify-content-center pb-3 pt-2">
                    <div className="me-md-2 ">
                      <AppButton
                        onClick={handleReset}
                        title={"CLEAR"}
                        variant={"secondary-md"}
                      />
                    </div>

                    <AppButton title={"SUBMIT"} variant={"primary-md"} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
