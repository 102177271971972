import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";
import { FaPlus } from "react-icons/fa";

import { useShowExemptionListMutation } from "../../Redux/Services/ExemptionApi";
import {
  EditOutlined,
  QuestionCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";

import { Modal, Button, Input, Table } from "antd";

import AppInput from "../AppInput/AppInput";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import AppSelect from "../AppSelect/AppSelect";
import { priceFormater, removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
import AppSwitch from "../AppSwitch/AppSwitch";
import { useSelector } from "react-redux";
const randomPass = require("secure-random-password");
const _ = require("lodash");

const exemptionDataResponse = [
  {search:"alkjsdlkjfa", keyword:"ajslkdfjalks"},
  {search:"alkjsdlkjfa", keyword:"ajslkdfjalks"},
  {search:"alkjsdlkjfa", keyword:"ajslkdfjalks"},
  {search:"alkjsdlkjfa", keyword:"ajslkdfjalks"},
  {search:"alkjsdlkjfa", keyword:"ajslkdfjalks"}
];

export default function MultiAssessed({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  disabled = false,
  whileEdit = false,
  _id,
}) {
  ///vendor contacts details
  const [active, setActive] = useState("2");

  const initailVal = {
    land_value: "",
    improvement_value: "",
    agriculture_market_value: "",
    agricultural_value: "",
    appraised_value: "",
    assessed_value: "",
    cap_loss: "",
    exemptions: "",
    is_proposed: false,
  };

  const options = [];
  let currentYear = new Date().getFullYear()+1;

  for (let i = currentYear; i > currentYear - 6; i--) {
    options.push(i);
  }

  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);
  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);
  const [showExemptionModal, setShowExemptionModal] = useState(false);
  const [exemptionData, setExemptionData] = useState(null);
  const [showExemption, {isLoading:showExemptionIsLoading}]= useShowExemptionListMutation();
  const { token, user } = useSelector((state) => state.auth);
  const { owner_name, address, year } = values;
  //state List

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const handleOpenModal = async () => {
    try {
      const response = await showExemption({ token })
      if(response){
        setExemptionData(response?.data || null);
        setShowExemptionModal(true)
      }
    } catch (error) {
      console.error("Error fetching exemption data", error);
    }
  };

  const handleCloseModal = () => {
    setShowExemptionModal(false);
  };

  const Columns = [
    {
      title: "Search",
      dataIndex: "search",
      key: "search",
      width: "50%",
    },
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      width: "50%",
    },
  ];

  const columns = [
    {
      title: <span>Year</span>,
      dataIndex: "year",
      key: "year",
      required: true,
      render: (value) => {
        return <>{value || " "}</>;
      },
      align: "center",
    },
    {
      title: <span>Land</span>,
      dataIndex: "land_value",
      key: "land_value",
      required: true,
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },
    {
      title: <span>Improvement</span>,
      dataIndex: "improvement_value",
      key: "improvement_value",
      required: true,
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },
    {
      title: <span>Ag Assessed</span>,
      dataIndex: "agricultural_value",
      key: "agricultural_value",
      required: true,
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },
    {
      title: <span>Ag Market</span>,
      dataIndex: "agriculture_market_value",
      key: "agriculture_market_value",
      required: true,
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },

    {
      title: <span>Appraised</span>,
      dataIndex: "appraised_value",
      key: "appraised_value",
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },
    {
      title: <span>Assessed</span>,
      dataIndex: "assessed_value",
      key: "assessed_value",
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },
    {
      title: <span>Cap</span>,
      dataIndex: "cap_loss",
      key: "cap_loss",
      render: (value) => {
        return <>{value ? priceFormater(value, true, false) : " "} </>;
      },
      align: "center",
    },
    {
      title: <span>Exemptions</span>,
      dataIndex: "exemptions",
      key: "exemptions",
      render: (value) => {
        return <>{value || ""}</>;
      },
      align: "center",
    },
    {
      title: <span>Proposed</span>,
      dataIndex: "is_proposed",
      key: "is_proposed",
      render: (value) => {
        return <>{value ? "YES" : "NO"}</>;
      },
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      className: `${!disabled ? "" : "d-none"}`,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const yearInfo = [];
  data?.map(({ year }) => {
    yearInfo.push(Number(year));
  });

  const myArrayFiltered = options?.filter((el) => {
    return !yearInfo.includes(el);
  });

  const inputs = [
    {
      id: 54540,
      name: "is_proposed",
      checked: values.is_proposed || "",
      type: "switch",
      column: "12",
      label: "Proposed",
      margin: "3",
      disabled: disabled,
    },
    {
      id: 2565,
      name: "year",
      type: "select",
      value: values.year || "",
      column: "3",
      options: myArrayFiltered,
      errorMessage: "Please select year!",
      required: true,
      label: "Year",
    },

    {
      id: 14545,
      name: "land_value",
      type: "number",
      value: values?.land_value || "",
      column: "3",
      placeholder: "Enter Land ",
      errorMessage: "Please enter land!",
      label: "Land",
      required: true,
    },
    {
      id: 4542,
      name: "improvement_value",
      type: "number",
      value: values?.improvement_value || "",
      column: "3",
      placeholder: "Enter improvement",
      errorMessage: "Please enter improvement!",
      label: "Improvement",
      required: true,
    },
    {
      id: 345,
      name: "agricultural_value",
      value: values?.agricultural_value || "",
      type: "number",
      column: "3",
      placeholder: "Enter Ag Assessed",
      errorMessage: "Please enter Ag Assessed!",
      label: "Ag Assessed",
      required: true,
    },
    {
      id: 345644,
      name: "agriculture_market_value",
      value: values?.agriculture_market_value || "",
      type: "number",
      column: "3",
      placeholder: "Enter Ag Market",
      errorMessage: "Please enter Ag Market!",
      label: "Ag Market",
      required: true,
    },
    {
      id: 4,
      name: "appraised_value",
      type: "number",
      column: "3",
      value: values?.appraised_value || "",
      placeholder: "Enter Appraised",
      label: "Appraised",
      errorMessage: "Please enter appraised!",
      required: true,
    },
    {
      id: 545,
      name: "assessed_value",
      type: "number",
      column: "3",
      value: values?.assessed_value || "",
      placeholder: "Enter Assessed",
      label: "Assessed",
      errorMessage: "Please enter assessed!",
      required: true,
    },
    {
      id: 6561,
      name: "cap_loss",
      type: "number",
      column: "3",
      value: values?.cap_loss || "",
      placeholder: "Enter Cap",
      label: "Cap",
      errorMessage: "Please enter cap!",
      required: true,
    },

    {
      id: 445,
      name: "exemptions",
      type: "text",
      value: values?.exemptions || "",
      column: "3",
      maxLength: 250,
      placeholder: "Enter Exemption",
      label: "Exemptions",
    },
    // {
    //   id: 54540,
    //   name: "is_proposed",
    //   checked: values.is_proposed || "",
    //   type: "switch",
    //   column: "2",
    //   label: "Proposed",
    //   margin: "3",
    //   disabled: disabled,
    // },
  ];
  const onChange = (e) => {
    setGetErr(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (
      values.assessed_value &&
      values.appraised_value &&
      addRowToggler &&
      !editId
    ) {
      let value =
        Number(values.appraised_value?.replace(
          /[|&;$%@"<>()+,]/g,
          ""
        )) -
        Number(values.assessed_value?.replace(
          /[|&;$%@"<>()+,]/g,
          ""
        ));
      setValues({
        ...values,
        cap_loss: value < 0 ? "$0" : `$${value}`,
      });
    } else if (!values.assessed_value && !values.appraised_value) {
      setValues({
        ...values,
        cap_loss: "",
      });
    }
  }, [values.assessed_value, values.appraised_value]);
  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });
      setData([...newData, values]);
      setAddRowToggler(false);
      updateContact && updateContact(values);
      setEditId(null);
      setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({
        ...values,
        temp_id: randomId,
      });
      setValues(initailVal);
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };
  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setEditId(null);
  };

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  //select handler
  const selectHandler = (property, value, arrtibute) => {
    setValues({
      ...values,
      [property]: value,
    });
  };

  useEffect(() => {
    setAddRowToggler(false);

    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose]);
  useEffect(() => {
    setData(initailValArr || []);
  }, [JSON.stringify(initailValArr)]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <div className="gray-table-col border rounded-3 bg-white my-1 p-2">
      <div className="h6 pb-2 mb-0 text-primary border-bottom  border-primary">
        Assessed Information
      </div>
      <div
        className={`d-flex ${"justify-content-between"} align-items-center  my-2 contact-forms`}
      >
        <h6 className="fs-18 fw-bold me-2"></h6>

       {/* Show Exemption Button */}
        <Button type="primary" onClick={handleOpenModal}>
          Show Exemption
        </Button>

        {!addRowToggler && data?.length > 0 && !disabled && (
          <div className={`add_icon`} onClick={addNewRecord}>
            <FaPlus />
          </div>
        )}
      </div>

      <AppTable
        // sort={sort}
        pagination={false}
        dataSource={_.orderBy(data, ["year"], ["desc"])}
        columns={columns}
      />

      {getErr && (
        <div className="mt-3">
          <Alert message={getErr} className="py-1 mx-3" type="error" showIcon />{" "}
        </div>
      )}
      {/* //loading  */}
      {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

      {(addRowToggler || data?.length < 1 || !initailValArr) && !disabled && (
        <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
          {inputs.map((input) => {
            if (input.type === "text") {
              return (
                <AppInput
                  defaultSet={afterClose}
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                />
              );
            } else if (input.type === "number") {
              return (
                <AppNumeric
                  key={input.id}
                  {...input}
                  type="text"
                  defaultSet={afterClose}
                  value={values[input.name]}
                  thousandSeparator={true}
                  placeholder={input.placeholder}
                  prefix={"$"}
                  decimalScale={7}
                  maxLength={14}
                  onChange={(value) => {
                    setValues({ ...values, [input.name]: value });
                  }}
                />
              );
            }
            else if (input.type == "switch" && !input.showType) {
              return (
                <AppSwitch
                  key={input.id}
                  onChange={appSwitchHanler}
                  {...input}
                />
              );
            }
            else if (input.type === "select" && input.name === "year") {
              return (
                <AppSelect
                  key={input.id}
                  {...input}
                  onChange={selectHandler}
                  options={
                    <>
                      {/* {console.log()} */}
                      <option value={editId ? editId.year : ""}>
                        {editId ? editId.year : "Select Year"}
                      </option>
                      {input?.options?.map((ele) => {
                        return (
                          <option key={ele} value={ele}>
                            {ele}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              );
            }
          })}
          <div className="col-12 col-lg-1 mt-3 p-0">
            <div className="d-flex">
              <span
                onClick={handleSubmit}
                className={`btn btn-primary  btn-sm edit-buttons ${!values.agricultural_value ||
                  !values.year ||
                  !values.land_value ||
                  !values.appraised_value ||
                  !values.assessed_value ||
                  !values.agriculture_market_value ||
                  !values.cap_loss
                  ? "disabled"
                  : "btn-success"
                  } `}
              >
                Save
              </span>
              <span
                onClick={cancelHandler}
                className="btn btn-primary ms-1 btn-sm edit-buttons"
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      )}
         {/* Modal for Exemption */}
      <Modal
         title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Exemtions Mapping</span>
            <CloseOutlined onClick={handleCloseModal} style={{ cursor: "pointer", color: "red" }} />
          </div>
        }
        visible={showExemptionModal}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        footer={null} 
      >
          <Table
            dataSource= {exemptionData? exemptionData?.data : exemptionDataResponse}
            columns={Columns}
            rowKey="search"  
            loading={showExemptionIsLoading} 
          />
      </Modal>
    </div>
  );
}
