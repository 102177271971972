import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import { Row } from "react-bootstrap";
import "./AppSelect.css";
// const onChange = (value) => {
//   console.log(`selected ${value}`);
// };
// const onSearch = (value) => {
//   console.log("search:", value);
// };
const AppSearchSelect = (props) => {
 
const [focused, setFocused] = useState(false);  
const [dropdownVisible, setDropdownVisible] = useState(true);
const [scrollPosition, setScrollPosition] = useState(0);
 
 const {
        subhead,
        column,
        label,
        name,
        errorMessage,
        onChange,
        id,
        _id,
        classlabelstyle,
        hide,
        margin,
        defaultSet,
        options,
        classStyle,
        disabled,
        ...inputProps
      } = props;
 
    //   console.log(options, 33 ,"AppSearchSelect.js")
//   const handleFocus = (e) => {
//     setFocused(true);
//   };
 
 
  const setOnChange = (e) => {
   const selecteddata = options?.find(object => object.value === e);
//    console.log(name, e, Option, <option data_id={selecteddata?.data_id} value={e}>{selecteddata?.label}</option>)
    onChange(name, e, <option data_id={selecteddata?.data_id} value={e}>{selecteddata?.label}</option>);
  };
 
 // returning the design component
  return <div className={`app-search-select col-12 col-md-${column}`}>
    {label && (
        <Row className={margin ? `mt-${margin}` : "mt-3"}>
          <label
            className={`${classlabelstyle || "ms-1 heading-5 text-dark"} `}
          >
            {label}
            {inputProps.required && <span className="text-danger">*</span>}
            {inputProps.lableIcon}{" "}
            {inputProps.isEdit && <span className="text-dark fw-bold"> :</span>}
          </label>
        </Row>
      )}
  {!inputProps.isEdit ?<Select
    showSearch
    optionFilterProp="label"
    style={{width:"100%", backgroundColor:"transparent", marginTop:"9px"}}
    onChange={setOnChange}
    // focused={focused.toString()}
    options={options}
    value={inputProps?.value ? inputProps.value: ""}
    // open={dropdownVisible}
    // onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
    getPopupContainer={(triggerNode) => triggerNode.parentNode}
  />
  : (
    <div className="mx-1 mt-0 w-100 heading-5" style={{ marginTop: 15 }}>
      {inputProps.value || "NA"}
    </div>
  )}
  </div>
};
 
export default AppSearchSelect;
 