import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// import "antd/dist/antd.css";
import "./MultiJurisdiction.css";
import {
  Popconfirm,
  Alert,
  Tooltip,
  Switch,
  Button,
  Table,
} from "antd";
import { FaPlus } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import AppTable from "../AppTable/AppTable";
import { useSelector } from "react-redux";
import { MdOutlineAccountBalanceWallet, MdOutlineDelete } from "react-icons/md";
import {
  compareChange,
  priceFormater,
} from "../../Controllers/Functions";
import { jurisdiction, useJuriInfoSelectMutation } from "../../Redux/Services/Jurisdiction";
import { GlobalContext } from "../../App";
import InlineTable from "../antd/Tables/InlineTable";
import { updateItem } from "../KendoComps/KendoTable/services";
import { FcPaid } from "react-icons/fc";
import Loading from "../Loading";
const randomPass = require("secure-random-password");
export const MultiJurisdiction2 = ({
  afterClose,
  is_deliquent_tax = false,
  initailValArr = [],
  addFormVal,
  juriListOptions,
  updateJurisdiction,
  deleteJurisdiction,
  isedit = false,
  parcelData,
  bulkEdit = false,
  newAdd,
  taxData,
  automationTime
}) => {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;

  const initialVal = {
    jurisdiction_id: "",
    jurisdiction_name: taxData?.tax_collector_name,
    tax_office_code: "",
    tax_amount: "",
    cad_office_code: "",
    penalty: "",
    total_due: "",
    notes: "",
    current_exemption_applied: "",
    estimated_tax_without_exemption: "",
    hom: "",
    ov65: "",
    due_date: "",
    total_amount_paid: "",
    disabled_person: "",
    exemption_other: "",
    bonds_issued: "",
    stand_by_fees: "",
    tax_rate: "",
    collection_percentage: "",
    early_pay_discounts_percentage: "",
    verified_date: "",
    balance: "",
    next_balance: "",
    prior_years: "",
    year: parcelData.year,
  };

  const [values, setValues] = useState(initialVal);
  const [data, setData] = useState([]);
  // console.log(data, 85)
  const [getError, setGetError] = useState(false);
  const [showfieldPrior, setShowFieldPrior] = useState(false);
  const [showExemptionOnly, setShowExemptionOnly] = useState(false);

  //redux data store
  const { token } = useSelector((state) => state.auth);

  //update info of tax juri details
  const [fetchJuriInfo, fetchJuriInfoResult] = useJuriInfoSelectMutation();

  //update info of tax juri details for select exiting data
  const [fetchJuriInfoExitsData, fetchJuriInfoExitsDataResult] =
    useJuriInfoSelectMutation();

  const [fetchPriorInfoJuriInfoExitsData, fetchPriorInfoJuriInfoExitsDataResult] =
    useJuriInfoSelectMutation();

  // useEffect for juri info based on juri code
  useEffect(() => {
    if (fetchJuriInfoResult.isSuccess) {
      // setJuriListOptions(fetchJuriInfoResult.data?.data);
      const { id, ...rest } = fetchJuriInfoResult.data?.data[0];
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });

      const newData = {
        ...rest,
        year: parcelData.year,
        tempId: randomId,
        tax_year: "",
        is_prior_year: false,
        jurisdiction_id: juriListOptions[0]?.id,
        jurisdiction_name: juriListOptions[0]?.display_name,
      };
      // setDataSource([...dataSource, newData]);
      setData([...data, newData]);
      updateJurisdiction && updateJurisdiction(newData);
      //after adding row scroll to bottom the table
    }
    if (fetchJuriInfoResult.isError) {
      setGetError(
        fetchJuriInfoResult.error?.data?.error ||
        fetchJuriInfoResult.error?.data?.message ||
        fetchJuriInfoResult.error.error ||
        fetchJuriInfoResult.error.data?.msg.sqlMessage
      );
      setValues(initialVal);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: fetchJuriInfoResult.isLoading });
  }, [fetchJuriInfoResult.isLoading]);

  // useEffect for juri info based on juri code
  useEffect(() => {
    if (fetchJuriInfoExitsDataResult.isSuccess) {
      try {
        // setJuriListOptions(fetchJuriInfoExitsDataResult.data?.data);
        const { id, ...rest } = fetchJuriInfoExitsDataResult.data?.data[0];
        // console.log({...rest}, 153)
        const juriData = juriListOptions.filter((ele) => {
          return ele.id === id;
        });

        const newData = {
          ...values,
          ...rest,
          jurisdiction_id: juriData[0].id,
          jurisdiction_name: juriData[0].display_name,
        };
        setData([...updateItem(newData, data)]);
        updateJurisdiction && updateJurisdiction(newData);
      } catch (error) {
        console.log(error)
      }
    }
    if (fetchJuriInfoExitsDataResult.isError) {
      setGetError(
        fetchJuriInfoExitsDataResult.error?.data?.error ||
        fetchJuriInfoExitsDataResult.error?.data?.message ||
        fetchJuriInfoExitsDataResult.error.error ||
        fetchJuriInfoExitsDataResult.error.data?.msg.sqlMessage
      );
      setValues(initialVal);
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: fetchJuriInfoExitsDataResult.isLoading,
    });
  }, [fetchJuriInfoExitsDataResult.isLoading]);

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    // //sending delete id
    deleteJurisdiction(id);
  };

  const juriListOptionsFilter = [];
  data?.map(({ jurisdiction_name }) => {
    juriListOptionsFilter.push(jurisdiction_name);
  });


  // const addpriorYearHandler = () => {
  //   const randomId = randomPass.randomPassword({
  //     length: 9,
  //     characters: randomPass.digits,
  //   });
  //   const newData = {
  //     jurisdiction_id: 999998,
  //     year: parcelData.year,

  //     jurisdiction_name: taxData.tax_collector_name,
  //     tempId: randomId,
  //   };
  //   setPriorData([newData]);
  //   //send prior data
  //   updateJurisdiction && updateJurisdiction(newData);
  // };
  //handler jurisdiction priorities
  
  const addmoreEntries = () => {
    fetchPriorInfoJuriInfoExitsData({
      data: {
        jurisdiction_id: juriListOptions[0].id,
        year: parcelData.year,
      },
      token,
    }).then(({ data }) => {
      if (data) {
        const [record] = data?.data
        if (record) {
          const { id, display_name, tax_year, ...rest } = record;
          const randomId = randomPass.randomPassword({
            length: 9,
            characters: randomPass.digits,
          });
          const newData = {
            jurisdiction_id: id,
            jurisdiction_name: display_name,
            tempId: randomId,
            is_prior_year: true,
            ...(taxData?.current_year_bill ? { prior_years: String(Number(taxData?.current_year_bill) - 1) } : {})
          };
          // console.log(newData);
          setPriorData([newData, ...priorData]);
          updateJurisdiction && updateJurisdiction(newData);
        }
      }
    });
    // const randomId = randomPass.randomPassword({
    //   length: 9,
    //   characters: randomPass.digits,
    // });
    // const newData = {
    //   jurisdiction_id: 999998,
    //   year: parcelData.year,
    //   jurisdiction_name: taxData.tax_collector_name,
    //   tempId: randomId,
    // };
    // setPriorData([newData]);
    // //send prior data
    // updateJurisdiction && updateJurisdiction(newData);
  };

  useEffect(() => {
    setShowFieldPrior(false);
    setData(initailValArr);
    setValues(initialVal);
    setPriorData(initailValArr);
    setGetError(false);
  }, [afterClose]);

  useEffect(() => {
    setData(initailValArr);
    setPriorData(initailValArr);
  }, [initailValArr]);

  //exemption columns
  const exemptionsColumns = [
    {
      title: <span> HOM</span>,
      dataIndex: "hom",
      key: "hom",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 60,
    },
    {
      title: <span>OV65</span>,
      dataIndex: "ov65",
      key: "ov65",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 60,
    },
    {
      title: <span>Disable Person</span>,
      dataIndex: "disabled_person",
      key: "disabled_person",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>First Responder</span>,
      dataIndex: "first_responder",
      key: "first_responder",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Verified Date</span>,
      dataIndex: "verified_date",
      key: "verified_date",
      align: "center",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      width: 100,
    },
    {
      title: <span>Other Exemption</span>,
      dataIndex: "exemption_other",
      key: "exemption_other",
      align: "center",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      width: 100,
    },
    {
      title: <span>Solar</span>,
      dataIndex: "solar",
      key: "solar",
      align: "center",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      width: 60,
    },
    {
      title: <span>Bonds Approved</span>,
      dataIndex: "bonds_approved",
      key: "bonds_approved",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: <span>Bonds Issues</span>,
      dataIndex: "bonds_issued",
      key: "bonds_issued",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 100,
    },
    {
      title: "EntityID",
      dataIndex: "tax_office_code",
      key: "tax_office_code",
      render: (value, record) => {
        return (
          <div>
            {record.jurisdiction_id === 999998 ? (
              <div className={"heading-5 text-danger fs-11 fw-bold"}>
                {value || <> &nbsp;</>}
              </div>
            ) : (
              <>
                {record.key === "total" ? (
                  <div className="heading-5 fs-10 fw-bold text-dark">{"-"}</div>
                ) : (
                  value || <> &nbsp;</>
                )}
              </>
            )}
          </div>
        );
      },

      align: "center",
      width: 100,
    },
    {
      title: <span>Stand By Fees</span>,
      dataIndex: "stand_by_fees",
      key: "stand_by_fees",
      render: (value) => {
        return <>{value || <> &nbsp;</>}</>;
      },
      align: "center",
      width: 100,
    },
  ];

  const mappedJuriLitsOptions = juriListOptions?.map(({ id, display_name }) => {
    return { id: display_name, name: display_name, data_name: id };
  });

  const cols = [
    {
      title: "Jurisdiction Name",
      dataIndex: "jurisdiction_name",
      key: "jurisdiction_name",
      type: "select",
      // options: mappedJuriLitsOptions?.filter(({ id, display_name }) => {
      //   if(!juriListOptionsFilter.includes(id)){
      //   return { id: display_name, name: display_name, data_name: id };
      //   }
      // }),
      options: juriListOptions?.map(({ id, display_name }) => {
        return { id: display_name, name: display_name, data_name: id };
      }),
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "jurisdiction_name")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">{value}</div>
            ) : (
              value || <> &nbsp;</>
            )}
          </div>
        );
      },
      width: 100,
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "EntityID",
      dataIndex: "tax_office_code",
      key: "tax_office_code",
      render: (value, record) => {
        return (
          <div>
            {(record.jurisdiction_id === 999998 || record?.is_prior_year)? (
              <div className={"heading-5 text-danger fs-11 fw-bold w-100"}>
                {value || <> &nbsp;</>}
              </div>
            ) : (
              <>
                {record.key === "total" ? (
                  <div className="heading-5 fs-10 fw-bold text-dark w-100">{"-"}</div>
                ) : (
                  value || <> &nbsp;</>
                )}
              </>
            )}
          </div>
        );
      },
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        }
        if (record.jurisdiction_id === 999999) {
          return false;
        } else {
          return false;
        }
      },
      align: "center",
      width: 100,
    },
    {
      title: "Jurisdiction Code",
      dataIndex: "cad_office_code",
      key: "cad_office_code",
      render: (value, record) => {
        return (
          <div>
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className={"heading-5 text-danger fs-11 fw-bold w-100"}>
                {value || <> &nbsp;</>}
              </div>
            ) : (
              <>
                {record.key === "total" ? (
                  <div className="heading-5 fs-10 fw-bold text-dark w-100">{"-"}</div>
                ) : (
                  value || <> &nbsp;</>
                )}
              </>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
    },
    {
      title: "Tax Rate Year",
      dataIndex: "tax_year",
      key: "tax_year",
      render: (value, record) => {
        return (
          <div className={`${(record.jurisdiction_id === 999998 || record?.is_prior_year) ? "heading-5 text-danger fs-11 fw-bold" : ""}`}>
            {(value || <> &nbsp;</>)}
          </div>
        );
      },
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        } else {
          return false;
        }
      },
      align: "center",
      width: 100,
    },
    {
      title: "Rate",
      dataIndex: "tax_rate",
      key: "tax_rate",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "tax_rate")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {value ? (
              `$${Number(value.replace(/[|&;$%@"<>()+,]/g, "")).toLocaleString(
                undefined,
                { minimumFractionDigits: 6 }
              )}`
            ) : (
              <> &nbsp;</>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "Estimated Tax",
      dataIndex: "estimated_tax_without_exemption",
      key: "estimated_tax_without_exemption",
      render: (value, record) => {
        return (
          <>
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                  priceFormater(value)
                  : <> &nbsp;</>}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <> &nbsp;</>
            )}
          </>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        }
        if (record.jurisdiction_id === 999999) {
          return false;
        } else {
          return false;
        }
      },
    },
    ...(taxData?.third_party_tax_flag ? [{
      title: <span style={{ fontSize: 14 }} className="text-primary fw-bold">Bill Year</span>,
      dataIndex: "bill_year",
      key: "bill_year",
      render: (value, record) => {
        return (
          <div className="text-primary" style={{ fontSize: 14 }}>
            {value || <> &nbsp;</>}
          </div>
        );
      },
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        }
        if (record.jurisdiction_id === 999999) {
          return false;
        } else {
          return false;
        }
      },
      align: "center",
      width: 100,
    },] : []),
    {
      title: "Base Amount",
      dataIndex: "tax_amount",
      key: "tax_amount",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "tax_amount")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? priceFormater(value) : <> &nbsp;</>}
              </div>
            ) : value ? (
              priceFormater(value)
            ) : (
              <> &nbsp;</>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        }
        if (record.jurisdiction_id === 999999 || record?.is_prior_year) {
          return false;
        } else {
          return false;
        }
      },
    },

    {
      title: "Penalities",
      dataIndex: "penalty",
      key: "penalty",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "penalty")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? (
                  //it will convert price format
                  priceFormater(value)
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 || record?.is_prior_year) {
          return !isedit
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "Fees",
      dataIndex: "stand_by_fees",
      key: "stand_by_fees",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "stand_by_fees")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? (
                  //it will convert price format
                  priceFormater(value)
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "Collects",
      dataIndex: "collects",
      key: "collects",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "collects")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value ? (
                  //it will convert price format
                  priceFormater(value)
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "Base Due",
      dataIndex: "total_due",
      key: "total_due",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "total_due")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                  priceFormater(value)
                  : <> &nbsp;</>}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <> &nbsp;</>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "balance")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                  priceFormater(value)
                  : <> &nbsp;</>}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <> &nbsp;</>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },
    {
      title: "Next Balance",
      dataIndex: "next_balance",
      key: "next_balance",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "next_balance")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value
                  ? //it will convert price format
                  priceFormater(value)
                  : <> &nbsp;</>}
              </div>
            ) : value ? (
              //it will convert price format
              priceFormater(value)
            ) : (
              <> &nbsp;</>
            )}
          </div>
        );
      },
      align: "center",
      width: 100,
      type: "number",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998) {
          return !isedit
        }
        if (record.jurisdiction_id === 999998) {
          return !isedit
        } else {
          return false;
        }
      },
    },

    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (value, record) => {
        return (
          <div
            className={`${compareChange(initailValArr, record, "due_date")
              ? "text-warning fw-bold"
              : null
              }`}
          >
            {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
              <div className="heading-5 text-danger fs-11 fw-bold">
                {value || <> &nbsp;</>}
              </div>
            ) : (
              value || <> &nbsp;</>
            )}
          </div>
        );
      },
      align: "center",
      width: 150,
      type: "date",
      editable: (record) => {
        if (record.jurisdiction_id !== 999998 && !record?.is_prior_year) {
          return false;
        } else {
          return !isedit
        }
      },
    },
    // {
    //   title: "Prior Year",
    //   dataIndex: "prior_years",
    //   key: "prior_years",
    //   render: (value, record) => {
    //     return (
    //       <div
    //         className={`${compareChange(initailValArr, record, "prior_years")
    //           ? "text-warning fw-bold"
    //           : null
    //           }`}
    //       >
    //         {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
    //           <div className="heading-5 text-danger fs-11 fw-bold">
    //             {value || <> &nbsp;</>}
    //           </div>
    //         ) : (
    //           value || <> &nbsp;</>
    //         )}
    //       </div>
    //     );
    //   },
    //   align: "center",
    //   width: 100,
    //   type: "text",
    //   editable: (record) => {
    //     if (record.jurisdiction_id !== 999998 && !record?.is_prior_year) {
    //       return false;
    //     } else {
    //       return !isedit
    //     }
    //   },
    // },
    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    deleteHandler(record);
    if (record.jurisdiction_id === 999998 || record?.is_prior_year) {
      const newData = priorData?.filter(
        (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
      );
      setPriorData(newData);
    } else {
      const newData = data?.filter(
        (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
      );
      setData(newData);
    }
  };

  const handleAdd = () => {
    fetchJuriInfo({
      data: {
        jurisdiction_id: juriListOptions[0].id,
        year: parcelData.year,
      },
      token,
    });
  };

  var total = {
    tax_rate: 0,
    tax_amount: 0,
    estimated_tax_without_exemption: 0,
    penalty: "",
    stand_by_fees: "",
    collects: 0,
    total_due: 0,
    balance: 0,
    next_balance: 0,
  };
  const summaryCallFun = useCallback(
    (pageData) => {
      let newrate = 0;
      let newfees = 0;
      let newpenalty = 0;
      let collects = 0;
      let newtax_amount = 0;
      let newestimated_tax_amount = 0;
      let newtotal_due = 0;
      let newbalance = 0;
      let newnext_balance = 0;

      pageData.forEach((row) => {
        if (row["tax_rate"]) {
          newrate += Number(row["tax_rate"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["stand_by_fees"]) {
          newfees += Number(
            row["stand_by_fees"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["collects"]) {
          collects += Number(row["collects"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["tax_amount"]) {
          newtax_amount += Number(
            row["tax_amount"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }

        if (row["total_due"]) {
          newtotal_due += Number(
            row["total_due"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["estimated_tax_without_exemption"]) {
          newestimated_tax_amount += Number(
            row["estimated_tax_without_exemption"].replace(
              /[|&;$%@"<>()+,]/g,
              ""
            )
          );
        }

        if (row["penalty"]) {
          newpenalty += Number(row["penalty"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        // if (row["total_amount_paid"]) {
        //   newtotal_amount_paid += Number(
        //     row["total_amount_paid"].replace(/[|&;$%@"<>()+,]/g, "")
        //   );

        // }

        if (row["balance"]) {
          newbalance += Number(row["balance"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["next_balance"]) {
          newnext_balance += Number(
            row["next_balance"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        total["tax_rate"] = newrate;
        total["tax_amount"] = newtax_amount;
        total["penalty"] = newpenalty;
        total["stand_by_fees"] = newfees;
        total["collects"] = collects;
        total["total_due"] = newtotal_due;
        total["estimated_tax_without_exemption"] = newestimated_tax_amount;
        total["balance"] = newbalance;
        total["next_balance"] = newnext_balance;
      });
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell editable={true} index={0}>
              <span className="text-success fw-bold d-block" style={{ width: 100 }}> Total</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={2} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  `$${newrate.toLocaleString(undefined, {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 6,
                  })}`
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={4} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {(
                  `$${newestimated_tax_amount.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`
                )}
              </span>
            </Table.Summary.Cell>
            {taxData?.third_party_tax_flag && <Table.Summary.Cell editable={true} index={3} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>

              </span>
            </Table.Summary.Cell>}
            <Table.Summary.Cell editable={true} index={3} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(newtax_amount, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={5} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(newpenalty, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={6} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(newfees, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={7} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(collects, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={8} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(newtotal_due, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(newbalance, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={10} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {priceFormater(newnext_balance, true)}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={11} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}></span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}></span>
            </Table.Summary.Cell>
            {!isedit && (
              <Table.Summary.Cell index={13} align="center">
                <span className="text-success fw-bold d-block" style={{ width: 100 }}></span>
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </>
      );
    },
    [isedit, total, data]
  );

  const oneEditFun = useCallback(
    async (value, id, type) => {

      let juriData = juriListOptions?.filter((ele) => {
        return ele.display_name === value?.jurisdiction_name;
      });
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { jurisdiction_id, ...rest } = value;
        if (type === "select" && juriData?.length > 0) {
          fetchJuriInfoExitsData({
            data: {
              jurisdiction_id: juriData[0].id,
              year: parcelData.year,
            },
            token,
          });
          // setValues({ ...values, ...value });
        } else if (type !== "select") {
          // console.log({...value,
          //   jurisdiction_id: newDataVals[0].id,
          // });
          updateJurisdiction &&
            updateJurisdiction({
              ...values,
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data]
  );

  const data1 = useMemo(
    () =>
      data.filter((ele) => {
        return ele.jurisdiction_id !== 999998 && !ele?.is_prior_year;
      }),
    [data]
  );

  const cols1 = useMemo(() => cols, [cols]);

  //prior data

  const [priorData, setPriorData] = useState([]);
  // const priorData1 = useMemo(
  //   () => priorData.filter((ele) => (ele.jurisdiction_id === 999998 || ele?.is_prior_year)),
  //   [priorData]
  // );
  const priorData1 = useMemo(
    () => priorData.filter((ele) => (ele?.is_prior_year)),
    [priorData]
  );


  const onPriorEditFun = useCallback(
    async (value, id, type) => {
      // removeBlank(value);
      let juriData = juriListOptions?.filter((ele) => {
        return ele.display_name === value?.jurisdiction_name;
      });
      // console.log(value)
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { jurisdiction_id, ...rest } = value;
        if (type === "select" && juriData?.length > 0) {
          // console.log(value, juriData)
          // const { id, display_name, ...rest } = record
          
          // const [{ id, display_name }] = juriData
          // const newData = {
          //   ...values,
          //   ...rest,
          //   ...(value?.id ? { id: value?.id } : { tempId: value?.tempId }),
          //   is_prior_year: value?.is_prior_year,
          //   jurisdiction_id: id,
          //   jurisdiction_name: display_name,
          // };
          // // console.log(newData)


          // setPriorData([...updateItem(newData, [...priorData])]);
          // updateJurisdiction && updateJurisdiction(newData);
          fetchPriorInfoJuriInfoExitsData({
            data: {
              jurisdiction_id: juriData[0].id,
              year: parcelData.year,
            },
            token,
          }).then(({ data }) => {
            try {
              if (data) {
                const [record] = data?.data
                if (record) {
                  // setJuriListOptions(fetchJuriInfoExitsDataResult.data?.data);
                  const { id, display_name, ...rest } = record
                  const newData = {
                    ...values,
                    ...rest,
                    tempId: value?.tempId,
                    is_prior_year: value?.is_prior_year,
                    jurisdiction_id: id,
                    // tax_year: "2000",
                    tax_year: "2023",
                    tax_amount: "0",
                    stand_by_fees: "0",
                    balance: "0",
                    collects: "0",
                    next_balance: "0",
                    penalty: "0",
                    total_due: "0",
                    estimated_tax_without_exemption:"0",
                    jurisdiction_name: display_name,
                  };
                  // console.log(newData)
                  // console.log(updateItem(newData, priorData))

                  setPriorData([...updateItem(newData, priorData)]);
                  updateJurisdiction && updateJurisdiction(newData);
                }
              }

            } catch (error) {
              console.log(error)
            }
          });
          // setValues({ ...values, ...value });
        } else if (value?.tempId) {
          const { id, ...res } = value;
          updateJurisdiction && updateJurisdiction(res);
        } else {
          updateJurisdiction &&
            updateJurisdiction({
              ...values,
              ...value,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [priorData]
  );

  let grandTotalVals = {
    tax_rate: 0,
    tax_amount: 0,
    penalty: 0,
    stand_by_fees: 0,
    collects: 0,
    total_due: 0,
    balance: 0,
    next_balance: 0,
    estimated_tax_without_exemption: 0,
  };

  let priorTotalVals = {
    tax_rate: 0,
    tax_amount: 0,
    penalty: 0,
    stand_by_fees: 0,
    collects: 0,
    total_due: 0,
    balance: 0,
    next_balance: 0,
    estimated_tax_without_exemption: 0,
  };

  const summaryPriorCallFun = useCallback(
    (pageData) => {
      let newrate = 0;
      let newfees = 0;
      let newpenalty = 0;
      let newtotal_amount_paid = 0;
      let collects = 0;
      let newtax_amount = 0;
      let newestimated_tax_amount = 0;
      let newtotal_due = 0;
      let newbalance = 0;
      let newnext_balance = 0;
      pageData.forEach((row) => {

        if (row["tax_rate"]) {
          newrate += Number(row["tax_rate"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["stand_by_fees"]) {
          newfees += Number(
            row["stand_by_fees"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["collects"]) {
          collects += Number(row["collects"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["tax_amount"]) {
          newtax_amount += Number(
            row["tax_amount"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }

        if (row["total_due"]) {
          newtotal_due += Number(
            row["total_due"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        if (row["estimated_tax_without_exemption"]) {
          newestimated_tax_amount += Number(
            row["estimated_tax_without_exemption"].replace(
              /[|&;$%@"<>()+,]/g,
              ""
            )
          );
        }
        if (row["penalty"]) {
          newpenalty += Number(row["penalty"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["total_amount_paid"]) {
          newtotal_amount_paid += Number(
            row["total_amount_paid"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }

        if (row["balance"]) {
          newbalance += Number(row["balance"].replace(/[|&;$%@"<>()+,]/g, ""));
        }

        if (row["next_balance"]) {
          newnext_balance += Number(
            row["next_balance"].replace(/[|&;$%@"<>()+,]/g, "")
          );
        }
        priorTotalVals["tax_rate"] = newrate;
        priorTotalVals["tax_amount"] = newtax_amount;
        priorTotalVals["penalty"] = newpenalty;
        priorTotalVals["stand_by_fees"] = newfees;
        priorTotalVals["collects"] = collects;
        priorTotalVals["total_due"] = newtotal_due;
        priorTotalVals["estimated_tax_without_exemption"] = newestimated_tax_amount;
        priorTotalVals["balance"] = newbalance;
        priorTotalVals["next_balance"] = newnext_balance;

        grandTotalVals["tax_rate"] = total.tax_rate + priorTotalVals?.tax_rate;
        grandTotalVals["tax_amount"] = total.tax_amount + priorTotalVals?.tax_amount;
        grandTotalVals["penalty"] = total.penalty + priorTotalVals?.penalty;
        grandTotalVals["stand_by_fees"] = total.stand_by_fees + priorTotalVals?.stand_by_fees;
        grandTotalVals["collects"] = total.collects + priorTotalVals?.collects;
        grandTotalVals["total_due"] = total.total_due + priorTotalVals?.total_due;
        grandTotalVals["estimated_tax_without_exemption"] =
          total.estimated_tax_without_exemption + priorTotalVals?.estimated_tax_without_exemption;
        grandTotalVals["balance"] = total.balance + priorTotalVals?.balance;
        grandTotalVals["next_balance"] = total.next_balance + priorTotalVals?.next_balance;

        // if (row?.is_prior_year) {

        // }

      });
      return (
        <>
          {/* //prior total number */}
          {/* <Table.Summary.Row>
            <Table.Summary.Cell index={0}>
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}> Prior Year Total</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-danger fw-bold d-block " style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-danger fw-bold d-block " style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="center">
              <span className="text-danger fw-bold d-block " style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={4} align="center">
              <span className="text-danger fw-bold d-block " style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            {taxData?.third_party_tax_flag && <Table.Summary.Cell editable={true} index={3} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>

              </span>
            </Table.Summary.Cell>}
            <Table.Summary.Cell index={3} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.tax_amount, true)
                }
              </span>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={5} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.penalty, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.stand_by_fees, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.collects, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.total_due, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.balance, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(priorTotalVals?.next_balance, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={11} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}></span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12} align="center">
              <span className="text-danger fw-bold d-block" style={{ width: 100 }}></span>
            </Table.Summary.Cell>

            {!isedit && (
              <Table.Summary.Cell index={13} align="center">
                <span className="text-danger fw-bold d-block" style={{ width: 100 }}></span>
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row> */}

          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>
              <span className="text-success fw-bold d-block" style={{ width: 100 }}> Grand Total</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <span className="text-success fw-bold d-block" style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="center">
              <span className="text-success fw-bold d-block " style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="center">
              <span className="text-success fw-bold d-block " style={{ width: 100 }}> </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  `$${grandTotalVals.tax_rate.toLocaleString(undefined, {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 6,
                  })}`
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell editable={true} index={4} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  `$${grandTotalVals.estimated_tax_without_exemption.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )}`
                }
              </span>
            </Table.Summary.Cell>
            {taxData?.third_party_tax_flag && <Table.Summary.Cell editable={true} index={3} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>

              </span>
            </Table.Summary.Cell>}
            <Table.Summary.Cell index={3} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.tax_amount, true)
                }
              </span>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={5} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.penalty, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.stand_by_fees, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.collects, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.total_due, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.balance, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}>
                {
                  priceFormater(grandTotalVals.next_balance, true)
                }
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={11} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}></span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12} align="center">
              <span className="text-success fw-bold d-block" style={{ width: 100 }}></span>
            </Table.Summary.Cell>

            {!isedit && (
              <Table.Summary.Cell index={13} align="center">
                <span className="text-success fw-bold d-block" style={{ width: 100 }}></span>
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        </>
      );
    },
    [isedit, grandTotalVals, priorTotalVals]
  );

  useEffect(() => {
    if (data1.length) {
      function toFixedValue(value) {
        return Number(value).toFixed(2)
      }
      const {
        collects,
        next_balance,
        balance,
        penalty,
        stand_by_fees,
        tax_amount,
        tax_rate,
        total_due,
        estimated_tax_without_exemption,
      } = total;
      addFormVal &&
        addFormVal(
          {
            jurisdiction_id: 999997,
            collects: `$${toFixedValue(collects)}`,
            next_balance: `$${toFixedValue(next_balance)}`,
            balance: `$${toFixedValue(balance)}`,
            penalty: `$${toFixedValue(penalty)}`,
            stand_by_fees: `$${toFixedValue(stand_by_fees)}`,
            tax_amount: `$${toFixedValue(tax_amount)}`,
            estimated_tax_without_exemption: `$${toFixedValue(estimated_tax_without_exemption)}`,
            tax_rate: `$${toFixedValue(tax_rate)}`,
            total_due: `$${toFixedValue(total_due)}`,
            year: parcelData.year,
            tax_year: parcelData.year,
            current_year_bill: parcelData.year,
          },
          {
            jurisdiction_id: 999998,
            collects: `$${toFixedValue(priorTotalVals.collects)}`,
            next_balance: `$${toFixedValue(priorTotalVals.next_balance)}`,
            balance: `$${toFixedValue(priorTotalVals.balance)}`,
            penalty: `$${toFixedValue(priorTotalVals.penalty)}`,
            stand_by_fees: `$${toFixedValue(priorTotalVals.stand_by_fees)}`,
            tax_amount: `$${toFixedValue(priorTotalVals.tax_amount)}`,
            estimated_tax_without_exemption: `$${toFixedValue(priorTotalVals.estimated_tax_without_exemption)}`,
            tax_rate: `$${toFixedValue(priorTotalVals.tax_rate)}`,
            total_due: `$${toFixedValue(priorTotalVals.total_due)}`,
          },
          {
            jurisdiction_id: 999999,
            collects: `$${toFixedValue(grandTotalVals.collects)}`,
            next_balance: `$${toFixedValue(grandTotalVals.next_balance)}`,
            balance: `$${toFixedValue(grandTotalVals.balance)}`,
            penalty: `$${toFixedValue(grandTotalVals.penalty)}`,
            stand_by_fees: `$${toFixedValue(grandTotalVals.stand_by_fees)}`,
            tax_amount: `$${toFixedValue(grandTotalVals.tax_amount)}`,
            estimated_tax_without_exemption: `$${toFixedValue(grandTotalVals.estimated_tax_without_exemption)}`,
            tax_rate: `$${toFixedValue(grandTotalVals.tax_rate)}`,
            total_due: `$${toFixedValue(grandTotalVals.total_due)}`,
          }
        );
    }
  }, [data1, priorData1]);

  useEffect(() => {
    newAdd && newAdd([...data, ...priorData]);
  }, [data, priorData]);

  const bulkHandler = (type) => {
    if (type === "new") {
      let newValue = [...data, ...priorData1].map((ele) => ({
        ...ele,
        tax_amount: "$0",
        total_due: "$0",
        balance: "$0",
        next_balance: "$0",
      }))
      newValue = [...new Set(newValue.map(ele => JSON.stringify(ele)))].map(ele => JSON.parse(ele))
      bulkEdit && bulkEdit("new_account", newValue)
      setData(newValue)
      setPriorData(priorData1.map((ele) => ({
        ...ele,
        tax_amount: "$0",
        total_due: "$0",
        balance: "$0",
        next_balance: "$0",
      })))
    } else {
      let newValue = [...data, ...priorData1].map((ele) => ({
        ...ele,
        total_due: "$0",
        balance: "$0",
        next_balance: "$0",
      }))
      newValue = [...new Set(newValue.map(ele => JSON.stringify(ele)))].map(ele => JSON.parse(ele))
      bulkEdit("paid", newValue)
      setData(newValue)
      setPriorData(priorData1.map((ele) => ({
        ...ele,
        total_due: "$0",
        balance: "$0",
        next_balance: "$0",
      })))
    }
  }

  return (
    <div>
      {
        //classname add tax-card-table
        <div className="tax-card-table gray-table  rounded-2 ">
          <div
            className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
          >
            <h6 className="fs-18 fw-bold me-2 "></h6>


            <div className="action-buttons d-flex align-items-center gap-2">
              {!isedit && <div onClick={() => bulkHandler("new")} className="cursor-pointer">
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"New Account"}
                >
                  <MdOutlineAccountBalanceWallet />
                </Tooltip>
              </div>}
              {!isedit && <div onClick={() => bulkHandler("paid")} className="cursor-pointer">
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"Paid"}
                >
                  <FcPaid />
                </Tooltip>
              </div>}
              {(
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="left"
                  title={!showExemptionOnly ? "Show Exemptions" : "Show Bills"}
                >
                  <span className={`me-2 d-inline-block fs-10 fw-bold ${taxData?.automation_status_id === 4 ? "text-danger" : "text-success"} `}>{automationTime}</span>
                  <span className="me-2 d-inline-block fs-10 fw-bold text-dark">
                    Bills / Exemptions
                  </span>
                  <Switch
                    onChange={() => {
                      setShowExemptionOnly(!showExemptionOnly);
                    }}
                    size="small"
                  ></Switch>
                </Tooltip>
              )}
              {!isedit && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"Add Prior Year Bill"}
                >
                  {fetchPriorInfoJuriInfoExitsDataResult.isLoading && <Loading isLoading={true} />}
                  {!fetchPriorInfoJuriInfoExitsDataResult.isLoading && <div
                    className={`tax-card-icon`}
                    onClick={addmoreEntries}
                  >
                    <AiOutlineMenuUnfold />
                  </div>

                  }

                </Tooltip>
              )}
              {!isedit && juriListOptions?.length > 0 && (
                <Tooltip
                  overlayInnerStyle={{
                    fontSize: 10,
                  }}
                  placement="bottom"
                  title={"Add Jurisdiction"}
                >
                  <div className={`tax-card-icon`} onClick={handleAdd}>
                    <FaPlus style={{ backgroundColor: "white" }} />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="gray-table-col">
            {getError && (
              <div className="mt-3">
                <Alert
                  message={getError}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}

            {!showExemptionOnly ? (
              <div className="gray-table-col">
                <p className="text-primary fw-bold mb-2">Bills {is_deliquent_tax && <span className="animate-pop fs-10 ">(Deliquent Tax available)</span>}</p>
                <InlineTable
                  onEdit={oneEditFun}
                  setData={setData}
                  data={data1}
                  cols={cols1}
                  summary={data1.length ? summaryCallFun : undefined}
                />
                {priorData1.length > 0 && (
                  <div>
                    {/* <Loading isLoading={fetchPriorInfoJuriInfoExitsDataResult.isLoading}>
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="bottom"
                        title={"Add more entries"}
                      >
                        <div
                          className={`tax-card-icon`}
                          onClick={addmoreEntries}
                        >
                          <AiOutlineMenuUnfold />
                        </div>
                      </Tooltip>
                    </Loading> */}

                    <InlineTable
                      onEdit={onPriorEditFun}
                      // onEdit={oneEditFun}
                      setData={setPriorData}
                      data={priorData1}
                      cols={[{
                        title: "Jurisdiction Name",
                        dataIndex: "jurisdiction_name",
                        key: "jurisdiction_name",
                        type: "select",
                        options: juriListOptions?.map(({ id, display_name }) => {
                          return { id: display_name, name: display_name, data_name: id };
                        }),
                        render: (value, record) => {
                          return (
                            <div
                              className={`${compareChange(initailValArr, record, "jurisdiction_name")
                                ? "text-warning fw-bold"
                                : null
                                }`}
                            >
                              {(record.jurisdiction_id === 999998 || record?.is_prior_year) ? (
                                <div className="heading-5 text-danger fs-11 fw-bold">{value}</div>
                              ) : (
                                value || <> &nbsp;</>
                              )}
                            </div>
                          );
                        },
                        width: 100,
                        editable: (record) => {
                          if (record.jurisdiction_id !== 999998) {
                            return !isedit
                          } else {
                            return false;
                          }
                        },
                      }, ...cols1.filter(({ dataIndex }) => dataIndex !== "jurisdiction_name"),]}
                      showHeader={false}
                      summary={summaryPriorCallFun}
                    />
                  </div>
                )}
                <p id="table-bottom"></p>
              </div>
            ) : (
              <div className="gray-table-col">
                <p className="text-primary fw-bold mb-2">Exemptions</p>
                <AppTable
                  pagination={false}
                  columns={[
                    {
                      title: <span> Jurisdiction Name</span>,
                      dataIndex: "jurisdiction_name",
                      key: "jurisdiction_name",
                      render: (value, record) => {
                        return (
                          <>
                            {record.jurisdiction_id === 999998 ? (
                              <div className="heading-5 text-danger fs-11 fw-bold">
                                {value}
                              </div>
                            ) : (
                              value || <> &nbsp;</>
                            )}
                          </>
                        );
                      },
                      align: "center",
                      width: 100,
                    },
                  ].concat(exemptionsColumns)}
                  dataSource={[...data1]}
                />
              </div>
            )}
          </div>
        </div>
      }
    </div >
  );
};