import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const subdivisionhoa = createApi({
  reducerPath: "subdivisionhoa",
  tagTypes: ["subdivisionhoa"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addSubdivisionHoa: builder.mutation({
      query: (data) => ({
        url: `/add-hoa-information-subdivision`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["subdivisionhoa"],
    }),
    
    SubdivisionHoaEdit: builder.query({
      query: (data) => ({
        url: `/edit-hoa-information-subdivision/${data.id}`,
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    SubdivisionHoaCollectorList: builder.query({
      query: (data) => ({
        url: `/list-hoa-information-subdivision`,
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateSubdivisionHoa: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-information-subdivision/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["subdivisionhoa"],
    }),  
  }),
});

export const {
  useAddSubdivisionHoaMutation,
  useSubdivisionHoaEditQuery,
  useUpdateSubdivisionHoaMutation,
  useSubdivisionHoaCollectorListQuery
} =  subdivisionhoa
