import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import { AiOutlineClose } from "react-icons/ai";
import { FiFilter, FiRotateCw } from "react-icons/fi";
import { EditOutlined } from "@ant-design/icons";
import {
	FaCheck,
	FaSearch,
	FaSortAmountDown,
	FaSortAmountUp,
} from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Input, Button } from "antd";
import { FaPlus } from "react-icons/fa";
import { useEffect } from "react";
//import {JurisdictionModal} from "./JurisdictionModal";
import HoaMgmtModal from "./HoaMgmtModal";
import {
	useHoaCountyListMutation,
	useHoaMgmtlistQuery,
	useHoaSateListMutation,
} from "../../../Redux/Services/HaoMgmt";
import AppFilterSelect from "../../../Components/AppFilterSelect/AppFilterSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import Hoa from "../HOA/Hoa";
import SearchColumns from "../../../Components/SearchColumns";
import { dateFormat } from "../../../Controllers/Functions";
const HoaMgmt = ({ refresh }) => {
	const [showHide, setShowHide] = useState(false);
	const [initialStateVal, setInitialStateVal] = useState(false);
	const [data, setData] = useState();
	const [editId, setEditId] = useState(null);
	const [search, setSearch] = useState("");
	const [open, setOpen] = useState(false);
	const [hoaMgmtValues] = useState({
		name: "",
		website: "",
		state_id: "",
		email: "",
		selDisplay: "",
		selReadOnly: "",
		fax: "",
		is_direct_contacts: false,
		is_mailing_address: true,
		date_verified: "",
		mailing_phone_number: "",
		mailing_email: "",
		mailing_address_one: "",
		mailing_address_two: "",
		mailing_state_id: "",
		mailing_city: "",
		mailing_zip_five: "",
		mailing_zip_four: "",
		// mailing_contact_name: "",
		mailing_contact_position: "",
		mailing_fax: "",
		physical_phone_number: "",
		physical_email: "",
		physical_address_one: "",
		physical_address_two: "",
		physical_state_id: "",
		physical_city: "",
		physical_zip_five: "",
		physical_zip_four: "",
		// physical_contact_name: "",
		physical_contact_position: "",
		physical_fax: "",
		is_collector_multiple_cities: false,
		third_patry_document_service_id: "",
		hoa_collectors_comments: [],
		hoa_collectors_fees: [],
		hoa_collectors_branches: [],
		hoa_collector_fiscal_periods:[],
		hoa_collector_assessed_information:[],
		hoa_collector_contacts:[],
		hoa_collectore_notes:[]
	});
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	const [filetrVal, setFiletrVal] = useState({ state: "", hoa: "" });
	const [countyOptions, setCountyOptions] = useState([]);
	//redux data store
	const { token } = useSelector((state) => state.auth);
	const { hoaMgmtModule } = useSelector((state) => state.flagRoles);

	//calling rtk query hook for geting jurisdiction list
	const hoaMgmtList = useHoaMgmtlistQuery(token);


	const [hoaCountyList, hoaCountyListResult] = useHoaCountyListMutation();
	const [hoaSateList, hoaSateListResult] = useHoaSateListMutation();

	//calling rtk query hook for geting HOA CO list

	//edit vendor module
	//edit vendor module
	const editHandler = (e) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(e.id);
	};

	//addVendor Handler function with dependency
	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};
	//create function for customer fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		// 	return false;
		// } else {
		// 	return true;
		// }
	}
	function closeModal(data) {
		setShowHide(data);
	}
	//create flag for initail state
	const afterClose = () => {
		setInitialStateVal(!initialStateVal);
		// setPreDefualtStateValues(preDefualtStateValues)
	};

	const defaultExpandable = {
		expandedRowRender: (ele) => {
			return (
				<div className="gray-table-col ">
					<Hoa optionsShow={false} parerentData={ele.hoa_names || []} />
				</div>
			);
		},
	};
	const columns = [
		{
			title: (
				<span>
					State{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			width: 200,
			...SearchColumns("state_name", "State Name"),
		},
		{
			title: (
				<span>
					Fees Verified Date{" "}
					{getsortfield === "date_verified" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "date_verified" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "date_verified" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "date_verified",
			key: "date_verified",
			align: "center",
			...SearchColumns("date_verified", "Date Verified"),
			width: 300,
			sorter: (a, b) => a.date_verified.localeCompare(b.date_verified),
			render(value) {
				return dateFormat(value)
			}
		},

		{
			title: (
				<span>
					Name{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			align: "center",
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: 300,
			...SearchColumns("name", " Name"),
		},

		{
			title: (
				<span>
					Website{" "}
					{getsortfield === "website" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "website" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "website" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "website",
			key: "website",
			align: "center",
			sorter: (a, b) => a.website.localeCompare(b.website),
			width: 300,
			...SearchColumns("website", "Website"),
		},
		{
			title: (
				<span>
					Direct Contacts{" "}
					{getsortfield === "is_direct_contacts" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
						getsortfield === "is_direct_contacts" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
						getsortfield === "is_direct_contacts" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "is_direct_contacts",
			key: "is_direct_contacts",
			render: (value) => {
				return <>{value ? <FaCheck /> : <AiOutlineClose />}</>;
			},
			width: 200,
			align: "center",
			sorter: (a, b) => a.is_direct_contacts - b.is_direct_contacts,
		},

		{
			title: <span>Actions</span>,
			dataIndex: "edit",
			key: "edit",
			align: "center",
			className: `${hoaMgmtModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
			render: (value, row) => (
				<EditOutlined
					onClick={() => editHandler(row)}
					cursor={"pointer"}
					size={"20px"}
					color="gray"
				/>
			),
		},
	];

	useEffect(() => {
		if (hoaMgmtList.isSuccess) {
			const newData = hoaMgmtList.data?.data.map(
				({ date_verified, website, ...ele }, index) => {
					return {
						...ele,
						website: website || " ",
						date_verified: date_verified || " ",
						key: index,
					};
				}
			);
			setData(newData);
		}
	}, [hoaMgmtList]);

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//calling rtk query hook for geting County list
	const countyList2 = useGetCountyListMutation();
	const [getCountyList] = countyList2;

	// useEffect for stateslist
	useEffect(() => {
		if (countyList2[1].isSuccess) {
			setCountyOptions(countyList2[1].data?.data);
		}
	}, [countyList2[1].isLoading]);

	useEffect(() => {
		if (hoaCountyListResult.isSuccess) {
			const newData = hoaCountyListResult.data?.data?.map(
				({ date_verified, website, ...ele }, index) => {
					return {
						...ele,
						website: website || " ",
						date_verified: date_verified || " ",
						key: index,
					};
				}
			);
			setData(newData);
		}
		if (hoaCountyListResult.isError) {
			setData([]);
		}
	}, [hoaCountyListResult.isLoading]);

	useEffect(() => {
		if (hoaSateListResult.isSuccess) {
			const newData = hoaSateListResult.data?.data?.map(
				({ date_verified, website, ...ele }, index) => {
					return {
						...ele,
						website: website || " ",
						date_verified: date_verified || " ",
						key: index,
					};
				}
			);
			setData(newData);
		}
		if (hoaSateListResult.isError) {
			setData([]);
		}
	}, [hoaSateListResult.isLoading]);
	//state list
	const { stateList } = useSelector((state) => state.stateList);

	//options for showing filters
	const options = [
		{
			name: "state",
			value: filetrVal.state,
			data: stateList?.map(({ state_name, id }) => {
				return { name: state_name, id };
			}),
		},
		{
			name: "county",
			value: filetrVal.county,
			data: countyOptions?.map(({ county_name, id }) => {
				return { name: county_name, id };
			}),
		},
	];

	//function
	function clearState() {
		setCountyOptions([]);
		//reset
		if (filetrVal.state) {
			hoaMgmtList.refetch();
		}
		setFiletrVal({
			...filetrVal,
			state: "",
			county: "",
		});
	}
	//function
	function clearCounty() {
		if (filetrVal.state && filetrVal.county) {
			hoaSateList({ id: filetrVal.state, token });
		}
		setFiletrVal({
			...filetrVal,
			county: "",
		});
	}

	useEffect(() => {
		setSearch("");
		setOpen(false);
		if (refresh === "hoa-mgmt") {
			hoaMgmtList.refetch();
		}
		clearCounty();
	}, [refresh]);

	return (
		<>
			{hoaMgmtList.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
						<AppModal
							showModal={showHide}
							onClose={closeModal}
							width={"90%"}
							height={400}
							confirmProp={true}
							afterClose={afterClose}
							// passing props for default values set
							element={
								<HoaMgmtModal
									setOpen={setOpen}
									AddVendorHandler={AddVendorHandler}
									editId={editId}
									afterClose={initialStateVal}
									setShowHide={setShowHide}
									initialValues={hoaMgmtValues}
								/>
							}
						/>
						{/* changing input values globaly  */}
						<div className=" me-1 w-25 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>

						<div className="filter-button ">
							<Button
								className="filter-btn"
								onClick={() => {
									setOpen(!open);
									setFiletrVal({ state: "", county: "" });
									if (open) {
										hoaMgmtList.refetch();
									}
								}}
								type={open ? "primary" : "default"}
							>
								<FiFilter type={open ? "primary" : "default"} />{" "}
								<span className="ms-1">Filter</span>
							</Button>
						</div>
						{hoaMgmtModule.map((item, index) => {
							if (item.add_button) {
								return (
									<div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
							}
							return null;
						})}

					</div>
					<div style={{ clear: "both" }}></div>
					{/* //fileter options for table data */}
					{open && (
						<div className="my-2 p-2 bg-white border shadow-sm d-flex align-items-center">
							<div className="row w-100">
								{options?.map(({ value, name, data }, index) => {
									return (
										<div className="col-12 col-lg-4">
											<AppFilterSelect
												name={name}
												value={value}
												options={data}
												onClear={() => {
													if (name === "state") {
														clearState();
													} else if (name === "county") {
														clearCounty();
													}
												}}
												onChange={(e) => {
													setFiletrVal({
														...filetrVal,
														[name]: e.target.value,
													});

													if (name === "state" && e.target.value) {
														//fetching county list
														getCountyList({
															state_id: { state_id: e.target.value },
															token,
														});
														//fetching data base
														hoaSateList({ id: e.target.value, token });
													}

													if (name === "state" && !e.target.value) {
														clearState();
													}

													if (name === "county" && e.target.value) {
														//fetching data base
														hoaCountyList({ id: e.target.value, token });
													}
													if (name === "county" && !e.target.value) {
														clearCounty();
													}
												}}
											/>
										</div>
									);
								})}
							</div>
							<div className="d-flex justify-centent-end ms-md-2">
								<Button
									className="reset-btn"
									onClick={() => {
										clearState();
									}}
									type="default"
								>
									<FiRotateCw /> <span className="ms-1"> Reset</span>
								</Button>
							</div>
						</div>
					)}
					<AppTable
						sort={sort}
						loading={
							hoaCountyListResult.isLoading ||
							hoaSateListResult.isLoading ||
							hoaMgmtList.isFetching
						}
						search={search}
						dataSource={data}
						columns={columns}
						expandable={defaultExpandable}
						defaultExpandable
					/>
				</>
			)}
		</>
	);
};

export default HoaMgmt;
