
import { Alert, notification, Tabs } from "antd";
import { Row } from "react-bootstrap";
import React, { useEffect, useState , useMemo} from "react";
import { useSelector } from "react-redux";
import AppInput from "../../../Components/AppInput/AppInput";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppButton from "../../../Components/AppButton/AppButton";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import MultiDivisionHoa from './MultiDivisionHoa'
import {
  removeBlank,
  scrollComp,
} from "../../../Controllers/Functions";
import { useAddSubdivisionHoaMutation, useSubdivisionHoaEditQuery, useUpdateSubdivisionHoaMutation } from "../../../Redux/Services/subdivisionHoa";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import { useFilterHoaBasedStateCountyMutation } from "../../../Redux/Services/hoamgtinfo";


export default function SubDivisionModal({
  callback,
  editId,
  initialValues,
  setShowHide,
  setOpen,
  afterClose,
  initailValArr = [],
}) {
  const [getError, setGetError] = useState(false);
  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  const [countyOptions, setCountyOptions] = useState([]);
  const [data, setData] = useState(initailValArr);
  // const [error, setError] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [hoaListOptions,setHoaListOptions] = useState()
  const countyList2 = useGetCountyListMutation();
	const [getCountyList] = countyList2;

  const [filterHoaname, filterHoanameResult] =useFilterHoaBasedStateCountyMutation();

	// useEffect for stateslist
	useEffect(() => {
		if (countyList2[1].isSuccess) {
			setCountyOptions(countyList2[1].data?.data);
		}
	}, [countyList2[1].isLoading]);
  //   values assign
  const [values, setValues] = useState(initialValues);
 
  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);

    //also hoa is checked set hao collector data in localstorage
    if (values?.save_hoa_local) {
      localStorage.setItem("subdivision", JSON.stringify(values));
    }
  }

  //calling rtk query hook for geting edit Hoa data   edit query will be here
  const editHomMgmtApi = useSubdivisionHoaEditQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //calling rtk query hook for geting edit Jurisdiction data
  const updateHoaMgmtApi = useUpdateSubdivisionHoaMutation();
  const [updateHoaMgmt, {isLoading:updateRecordIsLoading, isSuccess:updateRecordIsScuess}] = updateHoaMgmtApi;

  //calling redux stor for vendors list
  const { stateList } = useSelector((state) => state.stateList);

  //calling rtk query hook for adding hoa mgmt
  const addHoaMgmtApi = useAddSubdivisionHoaMutation();
  const [addHoaMgmt, {isLoading:addRecordIsLoading, isSuccess:addRecordIsSucess}] = addHoaMgmtApi;

  useEffect(()=>{
    if(callback && (updateRecordIsScuess ||addRecordIsSucess)){
      callback();
    }
  },[addRecordIsLoading, updateRecordIsLoading])

  //useEffect for setting default values
  useEffect(() => {
    setStateData(stateList);
    setValues(initialValues);
  }, [afterClose]);


  //useEffect for updateing Hoa
  useEffect(() => {
    if (updateHoaMgmtApi[1].isSuccess) {
      closeModal();
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateHoaMgmtApi[1].data?.message,
      });
      setOpen(false);
    }
    if (updateHoaMgmtApi[1].error) {
      setGetError(
        updateHoaMgmtApi[1].error?.data?.error ||
        updateHoaMgmtApi[1].error?.data?.message ||
        (updateHoaMgmtApi[1].error.error && "server unreachable!") ||
        updateHoaMgmtApi[1].error.data?.msg.sqlMessage
      );
    }

  }, [updateHoaMgmtApi[1].isLoading]);

  //useEffect for edit TaxCollector
  useEffect(() => {
    if (editHomMgmtApi.isSuccess) {
      const {
        ...othersData
      } = editHomMgmtApi.data?.data;
      var newArr = {
        ...othersData,
        add_hoa_information_id: [],
        edit_hoa_information_id: [],
        delete_hoa_information_id: [],
      };
      setValues(newArr);
    }
    if (editHomMgmtApi.isError) {
      setGetError(
        editHomMgmtApi.error?.data?.error ||
        editHomMgmtApi.error?.data?.message ||
        editHomMgmtApi.error?.data ||
        (editHomMgmtApi.error.error && "server unreachable!") ||
        editHomMgmtApi.error.data?.msg.sqlMessage
      );
    }
  }, [editHomMgmtApi.isFetching]);

  // useEffect for add hoa mgmt
  useEffect(() => {
    if (addHoaMgmtApi[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addHoaMgmtApi[1].data?.message,
      });
    }
    if (addHoaMgmtApi[1].error) {
      setGetError(
        addHoaMgmtApi[1].error?.data?.error ||
        addHoaMgmtApi[1].error?.data?.message ||
        (addHoaMgmtApi[1].error.error && "server unreachable!") ||
        addHoaMgmtApi[1].err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }
  }, [addHoaMgmtApi[1].isLoading]);

  //useEffect for editCustomer refecth
  useEffect(() => {
    if (editId) {
      editHomMgmtApi.refetch();
    }
    clearStatus();
  }, [afterClose]);

  const inputs = [
    {
      id: 1,
      name: "state_id",
      type: "select",
      value: values.state_id || "",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      options: stateData,
      required: true,
      column: "3",
    },
    {
      id: 2,
      name: "county_id",
      type: "select",
      value: values.county_id || "",
      placeholder: "Select County",
      errorMessage: "Please select county!",
      label: "County",
      options: countyOptions,
      required: true,
      column: "3",
    },
    {
      id: 3,
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      errorMessage: "Please enter name!",
      label: "Name",
      required: true,
      column: "3",
    },

    {
      id: 5,
      name: "code",
      type: "text",
      value: values?.code || "",
      placeholder: "Enter Code",
      errorMessage: "Please enter code!",
      label: "code",
      maxLength: 100,
      column: "3",
    },
  ];

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    //clear Status
    clearStatus();
  };

  //select handler
  const selectHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: Number(value) });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editHomMgmtApi.refetch();
    }
    clearStatus();
  };


  const handleSubmit = (e) => {
    e.preventDefault();
     {
      removeBlank(values);

      if (!editId) {
        addHoaMgmt({
          data: {add_hoa_information_subdivision:{...values, hoa_information_id:values?.hoa_information_id?.map(object=>Number(object?.hoa_information_id))}},
          token: token,
        });
      }
      if (editId) {
        updateHoaMgmt({
          data: {
            code: values?.code,
            county_id: values?.county_id,
            add_hoa_information_id:values?.add_hoa_information_id?.map(object=>Number(object?.hoa_information_id)),
            edit_hoa_information_id:values?.edit_hoa_information_id?.map(object=>({id:object.id, hoa_information_id:Number(object?.hoa_information_id)})),
            delete_hoa_information_id:values?.delete_hoa_information_id,
            name: values?.name,
            state_id: values?.state_id
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  useEffect(() => {
    if (filterHoanameResult.isSuccess) {
      setHoaListOptions(filterHoanameResult.data?.data?.hoa_information);
    }
    if (filterHoanameResult.error) {
      setGetError(
        filterHoanameResult.error?.data?.message ||
          filterHoanameResult.error.error ||
          filterHoanameResult.error.data?.msg.sqlMessage
      );
      // setHoaListOptions([]);
    }
  }, [filterHoanameResult.isLoading]);

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("hoamgtError");
    }
  }, [getError]);


 useEffect(()=>{
  getCountyList({
    state_id: { state_id: values?.state_id },
    token,
  });
 },[values?.state_id]);

 useEffect(()=>{
  if(values?.county_id){
  filterHoaname({ data: { state_id:values?.state_id, county_id: values?.county_id }, token });
  }
 },[values?.county_id]);



  return (
    <div className="position-relative mytab">
      <div className=" border-bottom border-2 " id="hoamgtError">
        <h5>{editId ? "Edit Subdivision HOA" : "Add Sudivision HOA"}</h5>
      </div>
      <div className="mt-3">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      <form onSubmit={handleSubmit} action="">
        {(editHomMgmtApi.isFetching || updateHoaMgmtApi[1].isLoading) && (
          <AppSpinner />
        )}
        <div
          className={`${editHomMgmtApi.isFetching
            ? "invisible"
            : ""
            } `}
        >
          <div className="row">
            <div className="col-12 col-lg-12">
              <Row>
                {inputs.map((input) => (
                  <>
                    
                    {(input.type === "text" ||
                      input.type === "email" ||
                      input.type === "date") &&
                      input.type !== "number" &&
                      !input?.tab &&
                      input?.address_type !== "annotation" && (
                        <AppInput
                          key={input.id}
                          {...input}
                          defaultSet={afterClose}
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      )}
                    {/* //appSelcet for state  */}
                    {input.type === "select" && (input.name === "county_id" || input.name === "state_id") && (
                      //base on user field flags
                      <AppSelect
                        key={input.id}
                        {...input}
                        defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select {input.name === "county_id"?"County": "State"}</option>
                            {input?.options?.map((ele) => {
                              return (
                                <option key={ele.id} value={ele.id}>
                                  {input.name === "county_id"?ele.county_name:ele.state_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  </>
                ))}
              </Row>
            </div>
          </div>

          {(
            <> 
              {
                <div className="row mt-4">
                 
                  <div  className="col-12 px-2">
                  <MultiDivisionHoa
                        hoaListOptions = {hoaListOptions}
                        afterClose={afterClose}
                        addFormVal={(value) => {
                          values.hoa_information_id = value;
                        }}
                        //add new
                        whileEdit={(value) => {
                          console.log("WhileEdit", value, editId, 392);
                          if (editId) {
                            setValues({
                              ...values,
                              add_hoa_information_id: [
                                ...values?.add_hoa_information_id,
                                value,
                              ],
                            });
                          }
                        }}
                        //update
                        updateContact={(value) => {
                          console.log("updateContact", editId, 404);
                          if (value?.temp_id) {
                            if (
                              values.add_hoa_information_id?.length > 0 &&
                              values.add_hoa_information_id?.find(
                                (val) => val["temp_id"] === value.temp_id
                              )
                            ) {
                              values.add_hoa_information_id.forEach((item) => {
                                if (item.temp_id === value.temp_id) {
                                  item.hoa_information_id = value.hoa_information_id;
                                }
                              });
                            }
                          }

                          if (editId) {
                            if (
                              values?.edit_hoa_information_id?.length > 0 &&
                              values?.edit_hoa_information_id?.find(
                                (val) => val["id"] === value.id
                              )
                            ) {
                              values?.edit_hoa_information_id.forEach(
                                (item) => {
                                  if (item.id === value.id) {
                                    item.hoa_information_id = value.hoa_information_id;
                                  }
                                }
                              );
                            } else {
                              if (value?.id) {
                                setValues({
                                  ...values,
                                  edit_hoa_information_id: [
                                    ...values?.edit_hoa_information_id,
                                    value,
                                  ],
                                });
                              }
                            }
                          }
                        }}
                        //delete
                        deleteContact={(value) => {
                          if (editId) {
                            if (value?.id) {
                              setValues({
                                ...values,
                                delete_hoa_information_id: [
                                  ...values?.delete_hoa_information_id,value.id
                                ],
                              });
                            } else {
                              setValues({
                                ...values,
                                add_hoa_information_id:
                                  values.add_hoa_information_id?.filter(
                                    (e) => {
                                      return e.temp_id !== value.temp_id;
                                    }
                                  ),
                              });
                            }
                          }
                        }}
                        initailValArr={
                          editId ? values?.hoa_information_id_list : []
                        }
                      />
                  </div>
                </div>
              }

{console.log(values?.delete_hoa_information_id, 481)}
              <div className="d-flex justify-content-center pb-3 pt-2">
                <div className="me-md-2 ">
                  <AppButton
                    onClick={handleReset}
                    title={"CLEAR"}
                    variant={"secondary-md"}
                  />
                </div>
                <AppButton title={"SUBMIT"} variant={"primary-md"} />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
