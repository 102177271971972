import { useEffect, useState } from "react";
import FormInput from "../../Components/AppInput/AppInput";
import { Form, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import "./Login.css";
import AppButton from "../../Components/AppButton/AppButton";
import { useUserLoginMutation } from "../../Redux/Services/authApi";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction } from "../../Redux/reducers/authReducer";
import { useCookies } from "react-cookie";

const CryptoJS = require("crypto-js");

const Login = () => {
  const [values, setValues] = useState({});
  const [remember, setRemember] = useState(false);
  const [, setCookies] = useCookies(["user"]);
  const [getError, setGetError] = useState(null);

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Please enter your email!",
      column: "12",
      required: true,
      icon: <FaUser />,
    },

    {
      id: 2,
      name: "password",
      type: "password",
      column: "12",
      placeholder: "Password",
      errorMessage: "Please enter your password!",
      required: true,
    },
  ];

  //creating rtk query
  const [userLogin, { isError, error, isSuccess, isLoading, data }] =
    useUserLoginMutation();
  const { token } = useSelector((state) => state.auth);

  //login request
  const handleSubmit = (e) => {
    e.preventDefault();
    userLogin(values);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setGetError("");
  };

  const dispatch = useDispatch();
  //use navigate for first time login
  const navigate = useNavigate();
  //useEffcet for login
  useEffect(() => {
    if (error) {
      setGetError(
        error.data?.msg?.sqlMessage ||
        error.data?.message ||
        (error?.error && "server unreachable")
      );
      setTimeout(() => {
        setGetError("");
      }, 15000);
    }
  }, [isLoading]);

  if (isSuccess) {
    try {
      const { profile_pic, ...otherData } = data.data[0];
      localStorage.setItem("avtar", profile_pic);
      if (
        (otherData?.user_type_id !== 15 && otherData?.user_type_id !== 5 && otherData?.user_type_id !== 20) ||
        otherData?.first_time_login === 1
      ) {
        navigate("/first-time-login");
      } else {
        navigate("/");
      }

      dispatch(
        userLoginAction({
          token: data.access_token,
          user: otherData,
        })
      );
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify({
          token: data.access_token,
          user: otherData,
        }),
        "$2b$10$hcPg5zgrb0tt8cg9UPgT1ex7c4VTc22bALT8pjNcfNMRbXZToEI46"
      ).toString();
      //cookies set
      setCookies("user", encrypted, {
        path: "/",
        maxAge: 14400,
      });

      if (remember) {
        localStorage.setItem("remember-me", JSON.stringify(values));
      }
    } catch (error) {
      console.log(error)
    }

    //storing county list and state list
  }

  const userValues = JSON.parse(localStorage.getItem("remember-me"));
  //remember me
  const rememberMeHandler = (e) => {
    if (values.email && values.password) {
      setRemember(e.target.checked);
      localStorage.setItem("remember-me", JSON.stringify(values));
    }
    if (e.target.checked === false) {
      localStorage.removeItem("remember-me");
    }
  };

  //check if remember-me exists
  useEffect(() => {
    if (
      window.location.href == "http://localhost:3000/login" ||
      window.location.href == "http://localhost:3000/"
    ) {
      if (userValues) {
        //redirect to dashboard
        setValues(userValues);
        setRemember(true);
      } else {
        setRemember(false);
      }
    } else {
      // setValues({});
    }

    //first time it will render
  }, []);

  return (
    <div className="mt-md-5 mt-3">
      <Container className=" mt-3">
        <Row className="align-self-center">
          <Col sm="5" lg="5" className="align-self-center my-md-0 my-5">
            <img className="logoImage " src="../Assets/login/Stellarlogo.png" alt="logo" />
            <div className="login-form">
              <h3 className="login-header heading-1">Login</h3>
              <p className="login-subtext description">
                login using your email id and password
              </p>

              {getError && (
                <Alert message={getError} type="error" showIcon closable />
              )}

              <Form onSubmit={handleSubmit}>
                <Row className="login-input-field">
                  {inputs.map((input) => (
                    <FormInput
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={onChange}
                    />
                  ))}
                </Row>

                <div className="my-2 d-flex align-items-center justify-content-between flex-wrap">
                  <div className="forgot-password d-flex align-items-center">
                    <input
                      type="checkbox"
                      checked={remember}
                      onChange={rememberMeHandler}
                      className="custom-control-input me-1 my-0"
                      id="customCheck1"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      Remember me
                    </label>
                  </div>

                  <div>
                    <NavLink
                      to="/forgot-password"
                      className="forgot-password  d-inline-block"
                    >
                      Forgot Your Password?
                    </NavLink>
                  </div>
                </div>
                <Row className="mt-4">
                  <AppButton
                    isLoading={isLoading}
                    variant={"primary"}
                    title={"Login"}
                  // onClick="OnClick"
                  />
                </Row>
              </Form>
            </div>
          </Col>

          <Col sm="6" lg="6" className="ms-auto d-flex justify-conten-center">
            <img
              className="loginImage img-fluid"
              src="../Assets/login/login.png"
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
