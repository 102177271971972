import React, { memo, useMemo, useState } from "react";
import { Button, Popconfirm, Select } from "antd";
import { FaPlus } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import {
  priceFormater,
  removeBlank,
} from "../../Controllers/Functions";
import { useCallback } from "react";
import InlineTable from "../antd/Tables/InlineTable";
const randomPass = require("secure-random-password");

const monthNames = [
  {id:"January", data_name:"January", name:"January"},
  {id:"February", data_name:"February", name:"February"},
  {id:"March", data_name:"March", name:"March"},
  {id:"April", data_name:"April", name:"April"},
  {id:"May", data_name:"May", name:"May"},
  {id:"June", data_name:"June", name:"June"},
  {id:"July", data_name:"July", name:"July"},
  {id:"August", data_name:"August", name:"August"},
  {id:"September", data_name:"September", name:"September"},
  {id:"October", data_name:"October", name:"October"},
  {id:"November", data_name:"November ", name:"November"},
  {id:"December", data_name:"December", name:"December"}
]

export default function FiscalPeriod({
  afterClose = false,
  initailValArr = [],
  updateDescription = false,
  deleteHandler = false,
  showLabel = true,
  isedit = false,

}) {
  const initailVal = {
    period_end_day: "",      // Text field
    period_start_day: "",      // Number field
    period_start_month: "",          // Number field
    period_end_month: "",    // Number field
  };
  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);

  useEffect(() => {
    setData(initailValArr);
    setValues(initailVal);
  }, [afterClose]);

  useEffect(() => {
    setData(initailValArr);
  }, [initailValArr]);

  // const juriListOptionsFilter = [];
  // data?.map(({ hoa_fees_type }) => {
  //   juriListOptionsFilter.push(hoa_fees_type);
  // });

  const handleAdd = () => {
    const randomId = randomPass.randomPassword({
      length: 9,
      characters: randomPass.digits,
    });

    const newData = {
      tempId: randomId,
      hoa_fees_type: "",
    };
    setData([...data, newData]);
    updateDescription && updateDescription(newData);
  };

  const cols = [
    {
      title: "Period Start Month",
      dataIndex: "period_start_month",
      key: "period_start_month",
      align: "center",
      type: "select",
      width:100,
      options: monthNames,
      render: (value) => {
         return value ? monthNames?.find(object=> object.id==value)?.name : <>&nbsp;</>
      },
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Period End Month",
      dataIndex: "period_end_month",
      showData:"name",
      key: "period_end_month",
      align: "center",
      type: "select",
      width: 100,
      options: monthNames,
      render: (value) => {
         return value ? monthNames?.find(object=> object.id==value)?.name : <>&nbsp;</>
      },
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Period Start Day",
      dataIndex: "period_start_day",
      key: "period_start_day",
      width: 100,
      align: "center",
      type: "notprice",
      render: (value, data) => (value? value :  <>&nbsp;</>),
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Period End Day",
      dataIndex: "period_end_day",
      key: "period_end_day",
      align: "center",
      type: "notprice",
      width: 100,
      render: (value, data) => (value? value :  <>&nbsp;</>),
      editable: () => {
        return !isedit;
      },
    }, 
    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      align: "center",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    deleteHandler && deleteHandler(record);
    const newData = data?.filter(
      (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
    );
    setData(newData);
  };

  const oneEditFun = useCallback(
    async (value, id, type) => {
      removeBlank(value);
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { ...rest } = value;
        if (type === "select") {
          updateDescription && updateDescription(rest);
        } else if (type !== "select") {
          updateDescription &&
            updateDescription({
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data, updateDescription]
  );

  const data1 = useMemo(() => data, [data]);

  return (
    <div className="gray-table-col  mb-2 p-2 ">
      <div
        className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
      >
        <div className="date align-items-center d-flex gap-1">
          {showLabel ? <h6 className="text-primary mb-0 border d-inline-block p-2 px-3 rounded bg-white shadow-sm">
            Fiscal Period
          </h6> : <div></div>}
        </div>
        {!isedit && (
          <div className={`add_icon ms-auto`} onClick={handleAdd}>
            <FaPlus />
          </div>
        )}
      </div>

      <div className="bg-white shadow-sm rounded border">
        <InlineTable
          onEdit={oneEditFun}
          setData={setData}
          data={data1}
          cols={cols}
        />
      </div>
    </div>
  );
}
